import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { QueryRenderer } from "react-relay";

import NewAndRecentlyRevisedCSVQuery from "./NewAndRecentlyRevisedCSVQuery";
import NewAndRecentlyRevisedQuery from "./NewAndRecentlyRevisedQuery";

import { AdvancedReportPage } from "pstat-anywhere/components/pages/reports";
import useQueryVariables from "pstat-anywhere/components/pages/reports/hooks/useQueryVariables";
import { Bucket } from "pstat-anywhere/components/reports/Buckets";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import theme from "pstat-anywhere/themes/policystat/theme";
import { useAdoptedMapping } from "pstat-anywhere/utils/hooks/useAdoptedMapping";


export const Buckets = ({ buckets }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Bucket
        label={t("documentReports.lessThanBucket", { count: 30 })}
        count={buckets?.["30"]}
        color="buckets.ok"
      />
      <Bucket
        label={t("documentReports.lessThanBucket", { count: 60 })}
        count={buckets?.["60"]}
        color="buckets.warning"
      />
      <Bucket
        label={t("documentReports.lessThanBucket", { count: 90 })}
        count={buckets?.["90"]}
        color="buckets.neutral"
      />
    </Fragment>
  );
};

function NewAndRecentlyRevisedReport({ route, router, location, labels }) {
  const { t } = useTranslation();
  const { variables } = useQueryVariables({ location });
  const desktopTitle = t(
    "documentReports.revisedRecent.newAndRecentlyRevised.headerDesktop",
    { documentLabelPlural: labels.documentLabelPlural }
  );
  const mobileTitle = t(
    "documentReports.revisedRecent.newAndRecentlyRevised.headerMobile"
  );

  const { buildDocumentsWithCollapseAdoptedFullReport } = useAdoptedMapping();

  return (
    <QueryRenderer
      environment={environment}
      variables={variables}
      query={NewAndRecentlyRevisedQuery}
      render={({ props }) => {
        const documents = props?.recentlyRevisedDocuments?.documents;
        return (
          <Media query={{ minWidth: theme.breakpoints[1] }}>
            {isDesktop => (
              <AdvancedReportPage
                csvQueryName="recentlyRevisedDocumentsCsv"
                csvQueryArgs={{}}
                CSVQuery={NewAndRecentlyRevisedCSVQuery}
                variables={variables}
                data={buildDocumentsWithCollapseAdoptedFullReport(documents)}
                route={route}
                router={router}
                location={location}
                tabs={[
                  {
                    id: "revised_recent_all",
                    textKey: "documentReports.tabs.all",
                    path: "policy_reports/revised/recent"
                  }
                ]}
                headerText={isDesktop ? desktopTitle : mobileTitle}
                noContentMessage={t(
                  "documentReports.revisedRecent.newAndRecentlyRevised.reportContent.noContentMessage",
                  {
                    documentLabel: labels.documentLabelPlural.toLowerCase()
                  }
                )}
                showActionButtonPanel={false}
                showTabsPanel={true}
                tableType={
                  isDesktop ? "revised_recent" : "revised_recent_mobile"
                }
                noSort={!isDesktop}
                BucketsComponent={() => (
                  <Buckets buckets={props?.recentlyRevisedDocuments?.buckets} />
                )}
                rssFeedUrl={props?.recentlyRevisedDocuments?.rssFeedUrl}
              />
            )}
          </Media>
        );
      }}
    />
  );
}

export default withLabelContext(NewAndRecentlyRevisedReport);

import graphql from "babel-plugin-relay/macro";

const ScheduledDocumentsQuery = graphql`
  query ScheduledDocumentsQuery(
    $after: String
    $first: Int
    $sort: String
    $showAll: Boolean
    $search_query: String
  ) {
    scheduledDocuments(sort: $sort, showAll: $showAll, q: $search_query) {
      documents(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            name
            effectiveDate
            hasAttachments
            restricted
            bloc {
              pk
              name
            }
            applicabilityType
            category {
              name
            }
            documentsOfTemplate {
              documents {
                pk
                name
                hasAttachments
                bloc {
                  name
                  pk
                }
                applicabilityType
                category {
                  name
                }
                effectiveDate
                restricted
              }
            }
          }
        }
      }
      error {
        statusCode
      }
    }
  }
`;

export default ScheduledDocumentsQuery;

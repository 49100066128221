import graphql from "babel-plugin-relay/macro";

export const AdminMenuQuery = graphql`
  query AdminMenuQuery {
    adminMenu {
      categoryCounts {
        pk
        name
        count
      }
      totalCount
      whitelist {
        description
        ipRangeStart
        ipRangeEnd
      }
      showBulkCopyAsNewLink
      showCustomerConfigLink
      showApiAccessTokensLink
      showSedEnableLink
      showImportLink
      showStaffMenuLink
      ahaIdeasPortalLink
      error {
        statusCode
      }
    }
  }
`;

export default AdminMenuQuery;

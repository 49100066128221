import { Box, Flex } from "grid-styled";
import { Children, Component } from "react";
import styled from "styled-components";
import { space, themeGet } from "styled-system";

import { StyledFontAwesomeIcon } from "../partials/icons";

import DropdownMenu from "./DropdownMenu";

import MenuButton from "pstat-anywhere/components/design-system/shared/MenuButton";

const SubMenuTitleFlex = styled(Flex)`
  width: 100%;
  height: 51px;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${themeGet("breakpoints.1")}) {
    width: auto;
    height: inherit;
  }
`;

const FullHeightBox = styled(Box)`
  height: 100%;
`;

const SubMenuArrow = styled(StyledFontAwesomeIcon).attrs({
  icon: ["fal", "caret-right"],
  size: "lg",
  color: "primary.0"
})`
  ${space};
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

const SubMenuArrowBox = styled(Box)`
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

export const SubMenuDivider = styled.li`
  background-color: ${themeGet("colors.nav.100")};
  margin-top: ${themeGet("space.2")}px;
  padding-bottom: ${themeGet("space.2")}px;
  height: 0;
  overflow: hidden;
  border-top: 1px solid ${themeGet("colors.nav.55")};
  @media (min-width: ${themeGet("breakpoints.1")}) {
    border-top: 1px solid ${themeGet("colors.nav.80")};
  }
`;

export const SubMenuSection = styled.div`
  margin-left: ${themeGet("space.3")}px;
`;

const DropdownItem = styled.li`
  display: block;
  float: none;
`;

const SubMenuTitle = props => {
  return (
    <SubMenuTitleFlex onClick={props.toggle} onKeyDown={props.keyboardToggle}>
      <FullHeightBox>
        {props.renderName ? (
          props.renderName
        ) : (
          <MenuButton color="nav.100" px={2} active={props.active}>
            {props.name}
          </MenuButton>
        )}
      </FullHeightBox>
      <SubMenuArrowBox px={4}>
        <SubMenuArrow />
      </SubMenuArrowBox>
    </SubMenuTitleFlex>
  );
};

export class SubMenu extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.keyboardToggle = this.keyboardToggle.bind(this);
    this.toggleOff = this.toggleOff.bind(this);
  }
  toggle() {
    this.props.toggle(this.props.name);
  }
  keyboardToggle(event) {
    if (event.key === " ") {
      event.preventDefault();
      this.toggle();
    }
  }
  toggleOff() {
    this.props.toggleOff();
  }
  render() {
    return (
      <DropdownMenu
        id={this.props.id}
        dropdownSelector={props => (
          <SubMenuTitle
            active={this.props.active}
            name={this.props.name}
            keyboardToggle={this.keyboardToggle}
            {...props}
          />
        )}
        expanded={this.props.expanded}
        rtl={this.props.rtl}
        toggle={this.toggle}
        closeMenu={this.props.closeMenu}
        renderName={this.props.renderName}
      >
        {Children.map(this.props.children, child =>
          // child can be null if viewer is guest
          child && child.type === SubMenuDivider
            ? child
            : child && <DropdownItem>{child}</DropdownItem>
        )}
      </DropdownMenu>
    );
  }
}

export default SubMenu;

import { useState } from "react";

export const useAdoptedMapping = () => {
  const [adoptedMapping, setAdoptedMapping] = useState({});

  const constructDocumentsOfTemplate = node => {
    const { documentsOfTemplate } = node;
    const { documents } = documentsOfTemplate;

    if (documents.length === 0) {
      return documentsOfTemplate;
    }

    const documentsWithAlreadySelectedDocument = [node, ...documents];

    const selectedDocument = adoptedMapping[node.pk] || node;
    return {
      ...documentsOfTemplate,
      documents: documentsWithAlreadySelectedDocument.filter(
        ({ pk }) => pk !== selectedDocument.pk
      )
    };
  };

  const onChangeAdoptedDocument = (updatedDocs, documentPk) => {
    setAdoptedMapping({
      ...adoptedMapping,
      [documentPk]: updatedDocs[0]
    });
  };

  const buildDocumentsWithCollapseAdoptedFullReport = documents => {
    if (documents && documents.edges) {
      return {
        ...documents,
        edges: documents.edges.map(({ node }) => ({
          ...{
            node: {
              ...(adoptedMapping[node.pk] || node),
              originPk: node.pk,
              onChangeAdoptedDocument,
              documentsOfTemplate: constructDocumentsOfTemplate(node)
            }
          }
        }))
      };
    }
    return documents;
  };

  const buildDocumentsWithCollapseAdoptedHomeReport = documents => {
    if (documents) {
      return documents.map(doc => ({
        ...(adoptedMapping[doc.pk] || doc),
        originPk: doc.pk,
        onChangeAdoptedDocument,
        documentsOfTemplate: constructDocumentsOfTemplate(doc)
      }));
    }
    return documents;
  };

  return {
    adoptedMapping,
    setAdoptedMapping,
    constructDocumentsOfTemplate,
    onChangeAdoptedDocument,
    buildDocumentsWithCollapseAdoptedFullReport,
    buildDocumentsWithCollapseAdoptedHomeReport
  };
};

/**
 * @flow
 * @relayHash d08a54b2d1c669b53897d8c2b327833c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type UnpublishedDocumentsForTemplateQueryVariables = {|
  pk: number,
  after?: ?string,
  first?: ?number,
  sort?: ?string,
|};
export type UnpublishedDocumentsForTemplateQueryResponse = {|
  +hiddenSystemDocumentTemplatesDocuments: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: string,
          +category: {|
            +name: string
          |},
          +bloc: {|
            +tenants: ?$ReadOnlyArray<?{|
              +name: string
            |}>,
            +name: string,
          |},
          +author: {|
            +firstName: string,
            +lastName: string,
            +title: ?string,
          |},
          +effectiveDate: ?string,
          +status: ?DocumentStatus,
          +link: ?string,
        |}
      |}>,
    |},
    +systemDocumentTemplateName: ?string,
    +systemDocumentTemplateStatus: ?DocumentStatus,
    +error: ?{|
      +statusCode: ?number
    |},
    +publishedDocumentsCount: ?number,
  |}
|};
export type UnpublishedDocumentsForTemplateQuery = {|
  variables: UnpublishedDocumentsForTemplateQueryVariables,
  response: UnpublishedDocumentsForTemplateQueryResponse,
|};
*/


/*
query UnpublishedDocumentsForTemplateQuery(
  $pk: Int!
  $after: String
  $first: Int
  $sort: String
) {
  hiddenSystemDocumentTemplatesDocuments(pk: $pk, sort: $sort) {
    documents(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          name
          category {
            name
            id
          }
          bloc {
            tenants {
              name
              id
            }
            name
            id
          }
          author {
            firstName
            lastName
            title
            id
          }
          effectiveDate
          status
          link(fullUrl: true)
          id
        }
      }
    }
    systemDocumentTemplateName
    systemDocumentTemplateStatus
    error {
      statusCode
    }
    publishedDocumentsCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk",
    "type": "Int!"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  v5
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": [
    {
      "kind": "Literal",
      "name": "fullUrl",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "link(fullUrl:true)"
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemDocumentTemplateName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemDocumentTemplateStatus",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishedDocumentsCount",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v18 = [
  v5,
  v17
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UnpublishedDocumentsForTemplateQuery",
  "id": "cc2311f0a3e58b1cc008103572b7c2c6",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UnpublishedDocumentsForTemplateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hiddenSystemDocumentTemplatesDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "SystemDocumentTemplateDocumentListType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryType",
                        "plural": false,
                        "selections": v6
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "tenants",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantType",
                            "plural": true,
                            "selections": v6
                          },
                          v5
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserType",
                        "plural": false,
                        "selections": [
                          v7,
                          v8,
                          v9
                        ]
                      },
                      v10,
                      v11,
                      v12
                    ]
                  }
                ]
              }
            ]
          },
          v13,
          v14,
          v15,
          v16
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UnpublishedDocumentsForTemplateQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hiddenSystemDocumentTemplatesDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "SystemDocumentTemplateDocumentListType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryType",
                        "plural": false,
                        "selections": v18
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "tenants",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantType",
                            "plural": true,
                            "selections": v18
                          },
                          v5,
                          v17
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserType",
                        "plural": false,
                        "selections": [
                          v7,
                          v8,
                          v9,
                          v17
                        ]
                      },
                      v10,
                      v11,
                      v12,
                      v17
                    ]
                  }
                ]
              }
            ]
          },
          v13,
          v14,
          v15,
          v16
        ]
      }
    ]
  }
};
})();
node.text = "query UnpublishedDocumentsForTemplateQuery(\n  $pk: Int!\n  $after: String\n  $first: Int\n  $sort: String\n) {\n  hiddenSystemDocumentTemplatesDocuments(pk: $pk, sort: $sort) {\n    documents(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          category {\n            name\n            id\n          }\n          bloc {\n            tenants {\n              name\n              id\n            }\n            name\n            id\n          }\n          author {\n            firstName\n            lastName\n            title\n            id\n          }\n          effectiveDate\n          status\n          link(fullUrl: true)\n          id\n        }\n      }\n    }\n    systemDocumentTemplateName\n    systemDocumentTemplateStatus\n    error {\n      statusCode\n    }\n    publishedDocumentsCount\n  }\n}\n";
(node/*: any*/).hash = '0025b06fc31d92395691f0110e61cbb9';
module.exports = node;

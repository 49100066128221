/**
 * @flow
 * @relayHash f99ff4c5a4f2e1765afe9ffbcc4667c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdminMenuQueryVariables = {||};
export type AdminMenuQueryResponse = {|
  +adminMenu: ?{|
    +categoryCounts: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: ?string,
      +count: ?number,
    |}>,
    +totalCount: ?number,
    +whitelist: ?$ReadOnlyArray<?{|
      +description: ?string,
      +ipRangeStart: ?string,
      +ipRangeEnd: ?string,
    |}>,
    +showBulkCopyAsNewLink: ?boolean,
    +showCustomerConfigLink: ?boolean,
    +showApiAccessTokensLink: ?boolean,
    +showSedEnableLink: ?boolean,
    +showImportLink: ?boolean,
    +showStaffMenuLink: ?boolean,
    +ahaIdeasPortalLink: ?string,
    +error: ?{|
      +statusCode: ?number
    |},
  |}
|};
export type AdminMenuQuery = {|
  variables: AdminMenuQueryVariables,
  response: AdminMenuQueryResponse,
|};
*/


/*
query AdminMenuQuery {
  adminMenu {
    categoryCounts {
      pk
      name
      count
    }
    totalCount
    whitelist {
      description
      ipRangeStart
      ipRangeEnd
    }
    showBulkCopyAsNewLink
    showCustomerConfigLink
    showApiAccessTokensLink
    showSedEnableLink
    showImportLink
    showStaffMenuLink
    ahaIdeasPortalLink
    error {
      statusCode
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "adminMenu",
    "storageKey": null,
    "args": null,
    "concreteType": "AdminMenuType",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "categoryCounts",
        "storageKey": null,
        "args": null,
        "concreteType": "CategoryCountType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pk",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "count",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "whitelist",
        "storageKey": null,
        "args": null,
        "concreteType": "IPWhitelistType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ipRangeStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ipRangeEnd",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "showBulkCopyAsNewLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "showCustomerConfigLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "showApiAccessTokensLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "showSedEnableLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "showImportLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "showStaffMenuLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ahaIdeasPortalLink",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AdminMenuQuery",
  "id": "a8a267624e87a4944312129eb2fd9f42",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AdminMenuQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "AdminMenuQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "query AdminMenuQuery {\n  adminMenu {\n    categoryCounts {\n      pk\n      name\n      count\n    }\n    totalCount\n    whitelist {\n      description\n      ipRangeStart\n      ipRangeEnd\n    }\n    showBulkCopyAsNewLink\n    showCustomerConfigLink\n    showApiAccessTokensLink\n    showSedEnableLink\n    showImportLink\n    showStaffMenuLink\n    ahaIdeasPortalLink\n    error {\n      statusCode\n    }\n  }\n}\n";
(node/*: any*/).hash = 'f46ca27b43c6dbad81848f70246f5e9a';
module.exports = node;

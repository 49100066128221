import { Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ContentContainerFlex } from "pstat-anywhere/components/pages";
import { WarningBanner } from "pstat-design-system/banner";
import { Text } from "pstat-design-system/text";

const getDueForReviewMessage = days => {
  let title = null;
  let message = "documentControl.view.systemDocumentTemplate.showDueForReview";
  if (days === 1) {
    title =
      "documentControl.view.systemDocumentTemplate.showDueForReviewOneDayBannerTitle";
  } else if (days > 0) {
    title =
      "documentControl.view.systemDocumentTemplate.showDueForReviewPluralBannerTitle";
  } else {
    message =
      "documentControl.view.systemDocumentTemplate.showPastDueForReview";
  }
  return { title, message };
};

const SystemDocumentTemplateWarningBanners = ({
  isSystemTemplateRetired,
  isPublishedTemplate,
  isNewVersionTemplateAvailable,
  isUnderReviewTemplate,
  expiringParentInDaysTemplate,
  hasScheduledTemplate,
  documentLabel
}) => {
  const { t } = useTranslation();
  const isParentExpiring = expiringParentInDaysTemplate !== null;
  let messageTitleKey = null;
  let messageBodyKey = null;
  const showScheduledTemplateBanner =
    hasScheduledTemplate && isPublishedTemplate && !isSystemTemplateRetired;
  if (isParentExpiring) {
    const { title, message } = getDueForReviewMessage(
      expiringParentInDaysTemplate
    );
    messageTitleKey = title;
    messageBodyKey = message;
  } else if (isUnderReviewTemplate) {
    messageTitleKey =
      "documentControl.view.systemDocumentTemplate.showUnderReviewBannerTitle";
    messageBodyKey =
      "documentControl.view.systemDocumentTemplate.showUnderReviewBanner";
  } else if (isSystemTemplateRetired) {
    messageBodyKey =
      "documentControl.view.systemDocumentTemplate.showRetiredBanner";
  } else if (!isPublishedTemplate) {
    messageBodyKey =
      "documentControl.view.systemDocumentTemplate.showUnpublishedBannerMsg";
    messageTitleKey =
      "documentControl.view.systemDocumentTemplate.showUnpublishedBannerTitle";
  } else if (isNewVersionTemplateAvailable) {
    messageBodyKey =
      "documentControl.view.systemDocumentTemplate.showNewVersionAvailableBanner";
  }
  return (
    <Fragment>
      {messageBodyKey && (
        <ContentContainerFlex>
          <WarningBanner width={"100%"}>
            <Flex flexDirection="column">
              {messageTitleKey ? (
                <Text color="nav.0" fontWeight="medium">
                  {t(messageTitleKey, {
                    documentLabel,
                    days: expiringParentInDaysTemplate
                  })}
                </Text>
              ) : (
                ""
              )}
              <Text color="nav.0" mt={1}>
                {t(messageBodyKey, { documentLabel })}
              </Text>
            </Flex>
          </WarningBanner>
        </ContentContainerFlex>
      )}
      {showScheduledTemplateBanner && (
        <ContentContainerFlex>
          <WarningBanner width={"100%"}>
            <Flex flexDirection="column">
              <Text color="nav.0" mt={1}>
                {t(
                  "documentControl.view.systemDocumentTemplate.showViewPolicyScheduledTemplateBanner",
                  { documentLabel }
                )}
              </Text>
            </Flex>
          </WarningBanner>
        </ContentContainerFlex>
      )}
    </Fragment>
  );
};

export default SystemDocumentTemplateWarningBanners;

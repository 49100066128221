/**
 * @flow
 * @relayHash 042b2fc45a5d338a5460cfecdaddd7d8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type RevisionStatus = "NEW" | "REVISED" | "UNCHANGED" | "%future added value";
export type NewAndRecentlyRevisedQueryVariables = {|
  after?: ?string,
  first?: ?number,
  sort?: ?string,
|};
export type NewAndRecentlyRevisedQueryResponse = {|
  +recentlyRevisedDocuments: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: string,
          +bloc: {|
            +pk: ?number,
            +name: string,
          |},
          +applicabilityType: ?ApplicabilityType,
          +category: {|
            +name: string
          |},
          +revisedFromParentStatus: ?RevisionStatus,
          +effectiveDate: ?string,
          +restricted: boolean,
          +hasAttachments: ?boolean,
          +documentsOfTemplate: ?{|
            +documents: ?$ReadOnlyArray<?{|
              +pk: ?number,
              +name: string,
              +hasAttachments: ?boolean,
              +bloc: {|
                +name: string,
                +pk: ?number,
              |},
              +applicabilityType: ?ApplicabilityType,
              +category: {|
                +name: string
              |},
              +effectiveDate: ?string,
              +revisedFromParentStatus: ?RevisionStatus,
            |}>
          |},
        |}
      |}>,
    |},
    +buckets: ?any,
    +rssFeedUrl: ?string,
  |}
|};
export type NewAndRecentlyRevisedQuery = {|
  variables: NewAndRecentlyRevisedQueryVariables,
  response: NewAndRecentlyRevisedQueryResponse,
|};
*/


/*
query NewAndRecentlyRevisedQuery(
  $after: String
  $first: Int
  $sort: String
) {
  recentlyRevisedDocuments(sort: $sort) {
    documents(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          name
          bloc {
            pk
            name
            id
          }
          applicabilityType
          category {
            name
            id
          }
          revisedFromParentStatus
          effectiveDate
          restricted
          hasAttachments
          documentsOfTemplate {
            documents {
              pk
              name
              hasAttachments
              bloc {
                name
                pk
                id
              }
              applicabilityType
              category {
                name
                id
              }
              effectiveDate
              revisedFromParentStatus
              id
            }
          }
          id
        }
      }
    }
    buckets
    rssFeedUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": [
    v5
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisedFromParentStatus",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasAttachments",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buckets",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rssFeedUrl",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": [
    v5,
    v14
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NewAndRecentlyRevisedQuery",
  "id": "e0b335cb4eba41aafc54ce05feb87289",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewAndRecentlyRevisedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recentlyRevisedDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "NewAndRecentlyRevisedType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5
                        ]
                      },
                      v6,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v4,
                              v5,
                              v11,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v5,
                                  v4
                                ]
                              },
                              v6,
                              v7,
                              v9,
                              v8
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12,
          v13
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewAndRecentlyRevisedQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recentlyRevisedDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "NewAndRecentlyRevisedType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v14
                        ]
                      },
                      v6,
                      v15,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v4,
                              v5,
                              v11,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v5,
                                  v4,
                                  v14
                                ]
                              },
                              v6,
                              v15,
                              v9,
                              v8,
                              v14
                            ]
                          }
                        ]
                      },
                      v14
                    ]
                  }
                ]
              }
            ]
          },
          v12,
          v13
        ]
      }
    ]
  }
};
})();
node.text = "query NewAndRecentlyRevisedQuery(\n  $after: String\n  $first: Int\n  $sort: String\n) {\n  recentlyRevisedDocuments(sort: $sort) {\n    documents(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          bloc {\n            pk\n            name\n            id\n          }\n          applicabilityType\n          category {\n            name\n            id\n          }\n          revisedFromParentStatus\n          effectiveDate\n          restricted\n          hasAttachments\n          documentsOfTemplate {\n            documents {\n              pk\n              name\n              hasAttachments\n              bloc {\n                name\n                pk\n                id\n              }\n              applicabilityType\n              category {\n                name\n                id\n              }\n              effectiveDate\n              revisedFromParentStatus\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n    buckets\n    rssFeedUrl\n  }\n}\n";
(node/*: any*/).hash = 'be172b8ffd7cb8b12ccb1a99a0006948';
module.exports = node;

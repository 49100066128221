/**
 * @flow
 * @relayHash e90fd8196e34020677bde5723e3b18e7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type TemplateReviewPageQueryVariables = {|
  documentPk: number
|};
export type TemplateReviewPageQueryResponse = {|
  +documentReview: ?{|
    +document: ?{|
      +pk: ?number,
      +name: string,
      +html: ?string,
      +author: {|
        +pk: ?number,
        +userDisplay: ?string,
        +firstName: string,
        +lastName: string,
        +profile: ?{|
          +title: ?string
        |},
      |},
      +category: {|
        +pk: ?number,
        +name: string,
      |},
      +bloc: {|
        +id: string,
        +pk: ?number,
        +name: string,
        +tenants: ?$ReadOnlyArray<?{|
          +pk: ?number,
          +name: string,
        |}>,
      |},
      +isTemplate: ?boolean,
      +status: ?DocumentStatus,
      +restricted: boolean,
      +effectiveDate: ?string,
      +approvalDate: ?string,
      +expirationDate: ?string,
      +revisionDate: ?string,
      +originationDate: ?string,
      +legacyEffectiveDate: ?string,
      +headerImageUrl: ?string,
      +attachments: ?$ReadOnlyArray<?{|
        +title: ?string,
        +fileUrl: ?string,
      |}>,
      +tags: ?$ReadOnlyArray<?{|
        +pk: ?number,
        +name: string,
      |}>,
      +hasScheduledTemplate: ?boolean,
    |},
    +showChangesTaskId: ?string,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
    +redirectUrl: ?string,
  |}
|};
export type TemplateReviewPageQuery = {|
  variables: TemplateReviewPageQueryVariables,
  response: TemplateReviewPageQueryResponse,
|};
*/


/*
query TemplateReviewPageQuery(
  $documentPk: Int!
) {
  documentReview(pk: $documentPk) {
    document {
      pk
      name
      html
      author {
        pk
        userDisplay
        firstName
        lastName
        profile {
          title
          id
        }
        id
      }
      category {
        pk
        name
        id
      }
      bloc {
        id
        pk
        name
        tenants(sort: "name") {
          pk
          name
          id
        }
      }
      isTemplate
      status
      restricted
      effectiveDate(formatted: true)
      approvalDate(formatted: true)
      expirationDate(formatted: true)
      revisionDate(formatted: true)
      originationDate(formatted: true)
      legacyEffectiveDate(formatted: true)
      headerImageUrl
      attachments {
        title
        fileUrl
        id
      }
      tags {
        pk
        name
        id
      }
      hasScheduledTemplate
      id
    }
    showChangesTaskId
    error {
      statusCode
      message
    }
    redirectUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "documentPk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "html",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userDisplay",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v9 = [
  v2,
  v3
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "sort",
    "value": "name",
    "type": "String"
  }
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isTemplate",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "formatted",
    "value": true,
    "type": "Boolean"
  }
],
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": v15,
  "storageKey": "effectiveDate(formatted:true)"
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalDate",
  "args": v15,
  "storageKey": "approvalDate(formatted:true)"
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDate",
  "args": v15,
  "storageKey": "expirationDate(formatted:true)"
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionDate",
  "args": v15,
  "storageKey": "revisionDate(formatted:true)"
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "originationDate",
  "args": v15,
  "storageKey": "originationDate(formatted:true)"
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyEffectiveDate",
  "args": v15,
  "storageKey": "legacyEffectiveDate(formatted:true)"
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "headerImageUrl",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileUrl",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasScheduledTemplate",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showChangesTaskId",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "redirectUrl",
  "args": null,
  "storageKey": null
},
v28 = [
  v2,
  v3,
  v10
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TemplateReviewPageQuery",
  "id": "9561d6bed12b09515665950a0168b8e5",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TemplateReviewPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentReview",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentReviewType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "UserType",
                "plural": false,
                "selections": [
                  v2,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "profile",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProfileType",
                    "plural": false,
                    "selections": [
                      v8
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryType",
                "plural": false,
                "selections": v9
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bloc",
                "storageKey": null,
                "args": null,
                "concreteType": "BlocType",
                "plural": false,
                "selections": [
                  v10,
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "tenants",
                    "storageKey": "tenants(sort:\"name\")",
                    "args": v11,
                    "concreteType": "TenantType",
                    "plural": true,
                    "selections": v9
                  }
                ]
              },
              v12,
              v13,
              v14,
              v16,
              v17,
              v18,
              v19,
              v20,
              v21,
              v22,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "attachments",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentAttachmentType",
                "plural": true,
                "selections": [
                  v8,
                  v23
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": null,
                "args": null,
                "concreteType": "TagType",
                "plural": true,
                "selections": v9
              },
              v24
            ]
          },
          v25,
          v26,
          v27
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TemplateReviewPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentReview",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentReviewType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "UserType",
                "plural": false,
                "selections": [
                  v2,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "profile",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProfileType",
                    "plural": false,
                    "selections": [
                      v8,
                      v10
                    ]
                  },
                  v10
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryType",
                "plural": false,
                "selections": v28
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bloc",
                "storageKey": null,
                "args": null,
                "concreteType": "BlocType",
                "plural": false,
                "selections": [
                  v10,
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "tenants",
                    "storageKey": "tenants(sort:\"name\")",
                    "args": v11,
                    "concreteType": "TenantType",
                    "plural": true,
                    "selections": v28
                  }
                ]
              },
              v12,
              v13,
              v14,
              v16,
              v17,
              v18,
              v19,
              v20,
              v21,
              v22,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "attachments",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentAttachmentType",
                "plural": true,
                "selections": [
                  v8,
                  v23,
                  v10
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": null,
                "args": null,
                "concreteType": "TagType",
                "plural": true,
                "selections": v28
              },
              v24,
              v10
            ]
          },
          v25,
          v26,
          v27
        ]
      }
    ]
  }
};
})();
node.text = "query TemplateReviewPageQuery(\n  $documentPk: Int!\n) {\n  documentReview(pk: $documentPk) {\n    document {\n      pk\n      name\n      html\n      author {\n        pk\n        userDisplay\n        firstName\n        lastName\n        profile {\n          title\n          id\n        }\n        id\n      }\n      category {\n        pk\n        name\n        id\n      }\n      bloc {\n        id\n        pk\n        name\n        tenants(sort: \"name\") {\n          pk\n          name\n          id\n        }\n      }\n      isTemplate\n      status\n      restricted\n      effectiveDate(formatted: true)\n      approvalDate(formatted: true)\n      expirationDate(formatted: true)\n      revisionDate(formatted: true)\n      originationDate(formatted: true)\n      legacyEffectiveDate(formatted: true)\n      headerImageUrl\n      attachments {\n        title\n        fileUrl\n        id\n      }\n      tags {\n        pk\n        name\n        id\n      }\n      hasScheduledTemplate\n      id\n    }\n    showChangesTaskId\n    error {\n      statusCode\n      message\n    }\n    redirectUrl\n  }\n}\n";
(node/*: any*/).hash = 'f8db62461f5c70ebbd0e421d86fcc96d';
module.exports = node;

import { Box } from "grid-styled";
import { Children, Component, cloneElement } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { alignItems, color, space, themeGet, width } from "styled-system";
import system from "system-components";

import MobileSearchIcon from "../document_search/MobileSearch/MobileSearch";
import { ContentContainerFlex } from "../pages";

import { SmallOnlyFlex } from "./flexUtils";
import { StyledFontAwesomeIcon } from "./icons";

import { layer } from "pstat-design-system/utils";
import { Text } from "pstat-design-system/text/index";

export const FLEX_HEADER_HEIGHT = 52;

export const FlexHeader = styled.header`
  ${color};
  ${space};
  ${alignItems};
  ${width};
  display: flex;
  height: ${FLEX_HEADER_HEIGHT}px;
`;

FlexHeader.defaultProps = {
  bg: "nav.0",
  color: "nav.100"
};

export const HamburgerToggler = styled.a`
  ${color};
  &:focus {
    outline: 0;
  }
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

const FocusBorder = system({
  border: "dashed 1px",
  borderColor: "transparent",
  borderRadius: 1,
  p: 1
}).extend`
  &:focus {
    outline: 0;
  }
  ${HamburgerToggler}:focus > & {
    border: dashed 1px ${themeGet("nav.100")};
  }
`;

FocusBorder.displayName = "FocusBorder";

const NavBackground = system(
  {
    position: "fixed",
    left: 0,
    width: "100%",
    height: "100%"
  },
  color,
  layer
).extend`
  top: ${props => (props.expanded ? `0` : `-120%`)};
  transition: top 0.3s ease;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

NavBackground.displayName = "NavBackground";

const Nav = styled.nav`
  ${color};
  ${layer};
  width: 100%;
  position: absolute;
  top: ${props => (props.expanded ? `0` : `-120%`)};
  left: ${props => (props.submenuExpanded ? `-120%` : `0`)};
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
  transition: top 0.3s ease, box-shadow 0.3s ease, left 0.3s ease;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    left: 0;
    top: 0;
    height: auto;
    position: relative;
    overflow-y: visible; /* allows submenus to work properly in large screens */
    visibility: visible;
  }
`;

// due to the absolute position, we must redefine the colours here
Nav.defaultProps = {
  bg: "nav.0",
  color: "nav.100",
  layer: "nav"
};

// Need for hiding contents from being in keyboard sequence when mobile nav collapsed
const NavContent = styled.div`
  visibility: ${props => (props.expanded ? `visible` : `hidden`)};
  /* Always show the nav when on desktop */
  @media (min-width: ${themeGet("breakpoints.1")}) {
    visibility: visible;
  }
`;

class _HamburgerHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggleHistoryHash = () => {
    if (window.location.hash === "#menu_open") {
      window.location.hash = "";
    } else {
      window.location.hash = "menu_open";
    }
  };

  handleHashChange = () => {
    if (this.state.expanded && window.location.hash !== "#menu_open") {
      this.setState({ expanded: false });
    }
  };

  toggle = () => {
    this.toggleHistoryHash();
    this.setState({ expanded: !this.state.expanded });
  };

  keyboardToggle = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      this.toggle();
    }
  };

  closeMenu = () => {
    this.props.closeSubMenu();
    this.setState({ expanded: false });
  };

  componentDidMount() {
    window.addEventListener("hashchange", this.handleHashChange);
  }

  render() {
    const { t } = this.props;
    return (
      <FlexHeader alignItems="center" width="100%">
        <ContentContainerFlex data-testid="header-container" px={[2, 4, 4]}>
          <HamburgerToggler
            onClick={this.toggle}
            onKeyDown={this.keyboardToggle}
            tabIndex={this.state.expanded ? "-1" : "0"}
            data-testid="hamburger-toggler"
          >
            <FocusBorder tabIndex="-1">
              <StyledFontAwesomeIcon
                icon={["far", "bars"]}
                size="lg"
                color="primary.0"
              />
              <Text size="normal" color="inherit">
                {t("buttons.menu")}
              </Text>
            </FocusBorder>
          </HamburgerToggler>
          <MobileSearchIcon navMenuExpanded={this.state.expanded} />
          <NavBackground
            expanded={this.state.expanded}
            bg="nav.0"
            layer="nav"
          />
          <Nav
            p={2}
            expanded={this.state.expanded}
            submenuExpanded={this.props.submenuExpanded}
            aria-label={t("nav.navBar.hamburgerMenu.ariaLabel")}
          >
            <SmallOnlyFlex
              justify="space-between"
              align="center"
              width="100%"
              p={3}
              borderBottom="1px solid"
              borderColor="nav.55"
              height="52px"
            >
              <Box>
                <HamburgerToggler
                  onClick={this.toggle}
                  onKeyDown={this.keyboardToggle}
                  tabIndex={this.state.expanded ? "0" : "-1"}
                >
                  <FocusBorder tabIndex="-1">
                    <StyledFontAwesomeIcon
                      icon={["far", "bars"]}
                      size="lg"
                      color="primary.0"
                    />
                    <Text size="normal" color="inherit">
                      {t("buttons.menu")}
                    </Text>
                  </FocusBorder>
                </HamburgerToggler>
              </Box>
              <Box>
                <HamburgerToggler
                  onClick={this.toggle}
                  onKeyDown={this.keyboardToggle}
                  tabIndex={this.state.expanded ? "0" : "-1"}
                >
                  <FocusBorder tabIndex="-1">
                    <StyledFontAwesomeIcon
                      icon={["far", "times"]}
                      size="lg"
                      color="primary.0"
                    />
                  </FocusBorder>
                </HamburgerToggler>
              </Box>
            </SmallOnlyFlex>
            <NavContent expanded={this.state.expanded}>
              {Children.map(this.props.children, child =>
                cloneElement(child, { closeMenu: this.closeMenu })
              )}
            </NavContent>
          </Nav>
        </ContentContainerFlex>
      </FlexHeader>
    );
  }
}

export default withTranslation()(_HamburgerHeader);

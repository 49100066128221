/**
 * @flow
 * @relayHash acb53c7a9e87fc01cadd7cad062059f3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type HomeContentQueriesPendingDocumentsQueryVariables = {||};
export type HomeContentQueriesPendingDocumentsQueryResponse = {|
  +pendingDocuments: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +pk: ?number,
          +name: string,
          +bloc: {|
            +name: string
          |},
          +applicabilityType: ?ApplicabilityType,
          +category: {|
            +name: string
          |},
          +effectiveDate: ?string,
          +restricted: boolean,
          +legacyEffectiveDate: ?string,
          +documentsOfTemplate: ?{|
            +documents: ?$ReadOnlyArray<?{|
              +pk: ?number,
              +name: string,
              +bloc: {|
                +pk: ?number,
                +name: string,
              |},
              +applicabilityType: ?ApplicabilityType,
              +category: {|
                +name: string
              |},
              +effectiveDate: ?string,
              +restricted: boolean,
              +legacyEffectiveDate: ?string,
            |}>
          |},
        |}
      |}>,
    |},
    +viewOnlyPks: ?$ReadOnlyArray<?number>,
  |}
|};
export type HomeContentQueriesPendingDocumentsQuery = {|
  variables: HomeContentQueriesPendingDocumentsQueryVariables,
  response: HomeContentQueriesPendingDocumentsQueryResponse,
|};
*/


/*
query HomeContentQueriesPendingDocumentsQuery {
  pendingDocuments(showAll: false) {
    documents(first: 5) {
      totalCount
      edges {
        node {
          id
          pk
          name
          bloc {
            name
            id
          }
          applicabilityType
          category {
            name
            id
          }
          effectiveDate
          restricted
          legacyEffectiveDate
          documentsOfTemplate {
            documents {
              pk
              name
              bloc {
                pk
                name
                id
              }
              applicabilityType
              category {
                name
                id
              }
              effectiveDate
              restricted
              legacyEffectiveDate
              id
            }
          }
        }
      }
    }
    viewOnlyPks
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "showAll",
    "value": false,
    "type": "Boolean"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5,
    "type": "Int"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  v5
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": v6
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyEffectiveDate",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewOnlyPks",
  "args": null,
  "storageKey": null
},
v13 = [
  v5,
  v3
],
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": v13
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "HomeContentQueriesPendingDocumentsQuery",
  "id": "d59d045bd3901deb093a19ba7402fafc",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "HomeContentQueriesPendingDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pendingDocuments",
        "storageKey": "pendingDocuments(showAll:false)",
        "args": v0,
        "concreteType": "PendingDocumentsReportType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": "documents(first:5)",
            "args": v1,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": v6
                      },
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v4,
                              v5,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v4,
                                  v5
                                ]
                              },
                              v7,
                              v8,
                              v9,
                              v10,
                              v11
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomeContentQueriesPendingDocumentsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pendingDocuments",
        "storageKey": "pendingDocuments(showAll:false)",
        "args": v0,
        "concreteType": "PendingDocumentsReportType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": "documents(first:5)",
            "args": v1,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": v13
                      },
                      v7,
                      v14,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v4,
                              v5,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v4,
                                  v5,
                                  v3
                                ]
                              },
                              v7,
                              v14,
                              v9,
                              v10,
                              v11,
                              v3
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      }
    ]
  }
};
})();
node.text = "query HomeContentQueriesPendingDocumentsQuery {\n  pendingDocuments(showAll: false) {\n    documents(first: 5) {\n      totalCount\n      edges {\n        node {\n          id\n          pk\n          name\n          bloc {\n            name\n            id\n          }\n          applicabilityType\n          category {\n            name\n            id\n          }\n          effectiveDate\n          restricted\n          legacyEffectiveDate\n          documentsOfTemplate {\n            documents {\n              pk\n              name\n              bloc {\n                pk\n                name\n                id\n              }\n              applicabilityType\n              category {\n                name\n                id\n              }\n              effectiveDate\n              restricted\n              legacyEffectiveDate\n              id\n            }\n          }\n        }\n      }\n    }\n    viewOnlyPks\n  }\n}\n";
(node/*: any*/).hash = 'c8d89f49e20ab748a1fb7e66377a2cb5';
module.exports = node;

/**
 * @flow
 * @relayHash 47d92e38a6ebf6a52d6a2f73a0d257cb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type RevisionStatus = "NEW" | "REVISED" | "UNCHANGED" | "%future added value";
export type PendingDocumentsQueryVariables = {|
  after?: ?string,
  first?: ?number,
  sort?: ?string,
  showAll?: ?boolean,
  search_query?: ?string,
  pending_approver?: ?number,
  bloc?: ?number,
|};
export type PendingDocumentsQueryResponse = {|
  +pendingDocuments: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: string,
          +hasAttachments: ?boolean,
          +revisedFromParentStatus: ?RevisionStatus,
          +effectiveDate: ?string,
          +restricted: boolean,
          +bloc: {|
            +name: string
          |},
          +applicabilityType: ?ApplicabilityType,
          +category: {|
            +name: string
          |},
          +approvalWorkflow: ?{|
            +name: ?string,
            +currentStep: ?{|
              +name: ?string,
              +created: ?any,
              +approvers: ?$ReadOnlyArray<?{|
                +firstName: string,
                +lastName: string,
                +isDocumentOwner: ?boolean,
                +hasApproved: ?boolean,
                +email: string,
                +notificationEmail: ?string,
              |}>,
            |},
          |},
          +revisedFromParent: ?boolean,
          +documentsOfTemplate: ?{|
            +documents: ?$ReadOnlyArray<?{|
              +pk: ?number,
              +name: string,
              +bloc: {|
                +pk: ?number,
                +name: string,
              |},
              +applicabilityType: ?ApplicabilityType,
              +category: {|
                +name: string
              |},
              +hasAttachments: ?boolean,
              +revisedFromParentStatus: ?RevisionStatus,
              +effectiveDate: ?string,
              +restricted: boolean,
              +revisedFromParent: ?boolean,
            |}>
          |},
        |}
      |}>,
    |},
    +viewOnlyPks: ?$ReadOnlyArray<?number>,
  |}
|};
export type PendingDocumentsQuery = {|
  variables: PendingDocumentsQueryVariables,
  response: PendingDocumentsQueryResponse,
|};
*/


/*
query PendingDocumentsQuery(
  $after: String
  $first: Int
  $sort: String
  $showAll: Boolean
  $search_query: String
  $pending_approver: Int
  $bloc: Int
) {
  pendingDocuments(after: $after, first: $first, sort: $sort, showAll: $showAll, q: $search_query, pendingApproverId: $pending_approver, blocId: $bloc) {
    documents(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          name
          hasAttachments
          revisedFromParentStatus
          effectiveDate(formatted: true)
          restricted
          bloc {
            name
            id
          }
          applicabilityType
          category {
            name
            id
          }
          approvalWorkflow {
            name
            currentStep {
              name
              created
              approvers(onlyNeededApprovers: true) {
                firstName
                lastName
                isDocumentOwner
                hasApproved
                email
                notificationEmail
                id
              }
              id
            }
            id
          }
          revisedFromParent
          documentsOfTemplate {
            documents {
              pk
              name
              bloc {
                pk
                name
                id
              }
              applicabilityType
              category {
                name
                id
              }
              hasAttachments
              revisedFromParentStatus
              effectiveDate(formatted: true)
              restricted
              revisedFromParent
              id
            }
          }
          id
        }
      }
    }
    viewOnlyPks
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "showAll",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search_query",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pending_approver",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bloc",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after",
  "type": "String"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first",
  "type": "Int"
},
v3 = [
  v1,
  {
    "kind": "Variable",
    "name": "blocId",
    "variableName": "bloc",
    "type": "Int"
  },
  v2,
  {
    "kind": "Variable",
    "name": "pendingApproverId",
    "variableName": "pending_approver",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "search_query",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "showAll",
    "variableName": "showAll",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v4 = [
  v1,
  v2
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasAttachments",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisedFromParentStatus",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": [
    {
      "kind": "Literal",
      "name": "formatted",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "effectiveDate(formatted:true)"
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v12 = [
  v7
],
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": v12
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "onlyNeededApprovers",
    "value": true,
    "type": "Boolean"
  }
],
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDocumentOwner",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasApproved",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "notificationEmail",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisedFromParent",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewOnlyPks",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v26 = [
  v7,
  v25
],
v27 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": v26
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PendingDocumentsQuery",
  "id": "3e4535e797b65e79e4bf04367177c431",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PendingDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pendingDocuments",
        "storageKey": null,
        "args": v3,
        "concreteType": "PendingDocumentsReportType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v4,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v6,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": v12
                      },
                      v13,
                      v14,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvalWorkflow",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalWorkflowType",
                        "plural": false,
                        "selections": [
                          v7,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "currentStep",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ApprovalStepType",
                            "plural": false,
                            "selections": [
                              v7,
                              v15,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "approvers",
                                "storageKey": "approvers(onlyNeededApprovers:true)",
                                "args": v16,
                                "concreteType": "ApprovalStepUserType",
                                "plural": true,
                                "selections": [
                                  v17,
                                  v18,
                                  v19,
                                  v20,
                                  v21,
                                  v22
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      v23,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v6,
                              v7,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v6,
                                  v7
                                ]
                              },
                              v13,
                              v14,
                              v8,
                              v9,
                              v10,
                              v11,
                              v23
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v24
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PendingDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "pendingDocuments",
        "storageKey": null,
        "args": v3,
        "concreteType": "PendingDocumentsReportType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v4,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v6,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": v26
                      },
                      v13,
                      v27,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvalWorkflow",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalWorkflowType",
                        "plural": false,
                        "selections": [
                          v7,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "currentStep",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ApprovalStepType",
                            "plural": false,
                            "selections": [
                              v7,
                              v15,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "approvers",
                                "storageKey": "approvers(onlyNeededApprovers:true)",
                                "args": v16,
                                "concreteType": "ApprovalStepUserType",
                                "plural": true,
                                "selections": [
                                  v17,
                                  v18,
                                  v19,
                                  v20,
                                  v21,
                                  v22,
                                  v25
                                ]
                              },
                              v25
                            ]
                          },
                          v25
                        ]
                      },
                      v23,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v6,
                              v7,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v6,
                                  v7,
                                  v25
                                ]
                              },
                              v13,
                              v27,
                              v8,
                              v9,
                              v10,
                              v11,
                              v23,
                              v25
                            ]
                          }
                        ]
                      },
                      v25
                    ]
                  }
                ]
              }
            ]
          },
          v24
        ]
      }
    ]
  }
};
})();
node.text = "query PendingDocumentsQuery(\n  $after: String\n  $first: Int\n  $sort: String\n  $showAll: Boolean\n  $search_query: String\n  $pending_approver: Int\n  $bloc: Int\n) {\n  pendingDocuments(after: $after, first: $first, sort: $sort, showAll: $showAll, q: $search_query, pendingApproverId: $pending_approver, blocId: $bloc) {\n    documents(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          hasAttachments\n          revisedFromParentStatus\n          effectiveDate(formatted: true)\n          restricted\n          bloc {\n            name\n            id\n          }\n          applicabilityType\n          category {\n            name\n            id\n          }\n          approvalWorkflow {\n            name\n            currentStep {\n              name\n              created\n              approvers(onlyNeededApprovers: true) {\n                firstName\n                lastName\n                isDocumentOwner\n                hasApproved\n                email\n                notificationEmail\n                id\n              }\n              id\n            }\n            id\n          }\n          revisedFromParent\n          documentsOfTemplate {\n            documents {\n              pk\n              name\n              bloc {\n                pk\n                name\n                id\n              }\n              applicabilityType\n              category {\n                name\n                id\n              }\n              hasAttachments\n              revisedFromParentStatus\n              effectiveDate(formatted: true)\n              restricted\n              revisedFromParent\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n    viewOnlyPks\n  }\n}\n";
(node/*: any*/).hash = '9f04bb9d83a1fca6669cbbf5af9bcbac';
module.exports = node;

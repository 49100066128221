/**
 * @flow
 * @relayHash 41bdde8c47f4ce863166c57f0f85e20a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type RevisionStatus = "NEW" | "REVISED" | "UNCHANGED" | "%future added value";
export type HomeContentQueriesNewAndRecentlyRevisedQueryVariables = {||};
export type HomeContentQueriesNewAndRecentlyRevisedQueryResponse = {|
  +recentlyRevisedDocuments: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: string,
          +bloc: {|
            +name: string
          |},
          +category: {|
            +name: string
          |},
          +applicabilityType: ?ApplicabilityType,
          +effectiveDate: ?string,
          +legacyEffectiveDate: ?string,
          +restricted: boolean,
          +revisedFromParent: ?boolean,
          +revisedFromParentStatus: ?RevisionStatus,
          +documentsOfTemplate: ?{|
            +documents: ?$ReadOnlyArray<?{|
              +pk: ?number,
              +name: string,
              +bloc: {|
                +pk: ?number,
                +name: string,
              |},
              +applicabilityType: ?ApplicabilityType,
              +category: {|
                +name: string
              |},
              +revisedFromParentStatus: ?RevisionStatus,
              +effectiveDate: ?string,
            |}>
          |},
        |}
      |}>,
    |},
    +buckets: ?any,
  |}
|};
export type HomeContentQueriesNewAndRecentlyRevisedQuery = {|
  variables: HomeContentQueriesNewAndRecentlyRevisedQueryVariables,
  response: HomeContentQueriesNewAndRecentlyRevisedQueryResponse,
|};
*/


/*
query HomeContentQueriesNewAndRecentlyRevisedQuery {
  recentlyRevisedDocuments {
    documents(first: 5) {
      totalCount
      edges {
        node {
          pk
          name
          bloc {
            name
            id
          }
          category {
            name
            id
          }
          applicabilityType
          effectiveDate
          legacyEffectiveDate
          restricted
          revisedFromParent
          revisedFromParentStatus
          documentsOfTemplate {
            documents {
              pk
              name
              bloc {
                pk
                name
                id
              }
              applicabilityType
              category {
                name
                id
              }
              revisedFromParentStatus
              effectiveDate
              id
            }
          }
          id
        }
      }
    }
    buckets
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5,
    "type": "Int"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  v3
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": v4
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyEffectiveDate",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisedFromParent",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisedFromParentStatus",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buckets",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v14 = [
  v3,
  v13
],
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": v14
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "HomeContentQueriesNewAndRecentlyRevisedQuery",
  "id": "b789cf4601663615695d27c36e5f2bf3",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "HomeContentQueriesNewAndRecentlyRevisedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recentlyRevisedDocuments",
        "storageKey": null,
        "args": null,
        "concreteType": "NewAndRecentlyRevisedType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": "documents(first:5)",
            "args": v0,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": v4
                      },
                      v5,
                      v6,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v2,
                              v3,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v2,
                                  v3
                                ]
                              },
                              v6,
                              v5,
                              v11,
                              v7
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomeContentQueriesNewAndRecentlyRevisedQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recentlyRevisedDocuments",
        "storageKey": null,
        "args": null,
        "concreteType": "NewAndRecentlyRevisedType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": "documents(first:5)",
            "args": v0,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v2,
                      v3,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": v14
                      },
                      v15,
                      v6,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v2,
                              v3,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v2,
                                  v3,
                                  v13
                                ]
                              },
                              v6,
                              v15,
                              v11,
                              v7,
                              v13
                            ]
                          }
                        ]
                      },
                      v13
                    ]
                  }
                ]
              }
            ]
          },
          v12
        ]
      }
    ]
  }
};
})();
node.text = "query HomeContentQueriesNewAndRecentlyRevisedQuery {\n  recentlyRevisedDocuments {\n    documents(first: 5) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          bloc {\n            name\n            id\n          }\n          category {\n            name\n            id\n          }\n          applicabilityType\n          effectiveDate\n          legacyEffectiveDate\n          restricted\n          revisedFromParent\n          revisedFromParentStatus\n          documentsOfTemplate {\n            documents {\n              pk\n              name\n              bloc {\n                pk\n                name\n                id\n              }\n              applicabilityType\n              category {\n                name\n                id\n              }\n              revisedFromParentStatus\n              effectiveDate\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n    buckets\n  }\n}\n";
(node/*: any*/).hash = '36ef8ed4f01bc04cbd82944392dfd13b';
module.exports = node;

import { Box, Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";

import { StyledForm } from "pstat-anywhere/components/document_control/approve_policy/ApprovalModal/components";
import SharableBlocsQuery from "pstat-anywhere/components/document_control/view_policy/share/share_to_sites/SharableBlocsQuery";
import publishSystemDocumentTemplates from "pstat-anywhere/components/system_document_templates/PublishTemplatesMutation";
import theme from "pstat-anywhere/themes/policystat/theme";
import { LocationsFormInput } from "pstat-design-system/inputs/FormElements";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { H3, Text } from "pstat-design-system/text";

export const ShareToSitesContent = ({ onClick, mt }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <H3 color="nav.0" fontWeight={theme.fontWeights.normal} mt={mt}>
        {t("documentControl.view.share.modal.shareToSites.title")}
      </H3>
      <Text py={3}>
        {t("documentControl.view.share.modal.shareToSites.description")}
      </Text>
      <Button
        buttonStyle="secondary"
        onClick={onClick}
        data-testid="shareToSitesContent-open-button"
      >
        {t("documentControl.view.share.modal.shareToSites.shareButtonLabel")}
      </Button>
    </Fragment>
  );
};

export const ShareToSitesModal = ({
  isOpen,
  isProcessing,
  shareableBlocs,
  selectedBlocPks,
  onClear,
  onChange,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      contentLabel={t(
        "documentControl.view.share.modal.shareToSites.shareToSitesModal.contentLabel"
      )}
      isOpen={isOpen}
      onRequestClose={onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              <Button
                id="close_share_to_sites_button"
                buttonStyle="tertiary"
                onClick={onClose}
              >
                {t("buttons.cancel")}
              </Button>
            </Box>
            <Box>
              <Button
                id="confirm_share_to_sites_button"
                processing={isProcessing}
                disabled={selectedBlocPks.length === 0}
                buttonStyle="secondary"
                onClick={onConfirm}
                data-testid="shareToSitesModal-confirm-button"
              >
                {t(
                  "documentControl.view.share.modal.shareToSites.shareToSitesModal.shareButtonLabel"
                )}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" flexWrap="wrap" pb={60} mx={1}>
        <StyledForm>
          <Text display={"inline-block"} fontSize={2} mb={6}>
            {t(
              "documentControl.view.share.modal.shareToSites.shareToSitesModal.description"
            )}
          </Text>
          <LocationsFormInput
            value={selectedBlocPks}
            name="locations"
            options={shareableBlocs}
            identityBlocs={shareableBlocs}
            onChange={onChange}
            onClear={onClear}
            inputLabel={t(
              "documentControl.view.share.modal.shareToSites.shareToSitesModal.inputLabel"
            )}
            hiddenTooltip={true}
            placeholder={t(
              "documentControl.view.share.modal.shareToSites.shareToSitesModal.inputPlaceholder"
            )}
            suggestionsMaxHeight="80px"
          />
        </StyledForm>
      </Flex>
    </Modal>
  );
};

const ShareToSites = ({
  documentPk,
  systemDocumentTemplatePk,
  mt,
  environment
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShareProcessing, setIsShareProcessing] = useState(false);
  const [selectedBlocPks, setSelectedBlocPks] = useState([]);

  const { t } = useTranslation();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    setIsShareProcessing(true);
    publishSystemDocumentTemplates(
      [systemDocumentTemplatePk],
      selectedBlocPks,
      (data, errors) => {
        setIsShareProcessing(false);
        const result = data?.publishSystemDocumentTemplates;
        if (errors || !result?.ok || result?.errors) {
          createErrorMessage(
            t(
              "documentControl.view.share.modal.shareToSites.shareToSitesModal.shareErrorMessage"
            )
          );
        } else {
          const publishSuccessSites =
            result?.publish_success?.[systemDocumentTemplatePk] ?? [];
          publishSuccessSites.length > 0
            ? createSuccessMessage(
                t(
                  "documentControl.view.share.modal.shareToSites.shareToSitesModal.shareSuccessMessage",
                  {
                    sites: publishSuccessSites.join(", ")
                  }
                )
              )
            : createSuccessMessage(
                t(
                  "documentControl.view.share.modal.shareToSites.shareToSitesModal.shareSuccessNoTemplates"
                )
              );

          handleClose();
        }
      }
    );
  };

  const handleChange = event => {
    setSelectedBlocPks(event.target.value);
  };

  const handleClear = () => {
    setSelectedBlocPks([]);
  };

  if (!systemDocumentTemplatePk) {
    return null;
  }

  return (
    <QueryRenderer
      environment={environment}
      query={SharableBlocsQuery}
      variables={{ documentPk: documentPk }}
      render={({ props }) => {
        if (!props) {
          return <LoadingSpinner size={30} />;
        }

        if (!props.blocsShareable?.blocs) {
          return null;
        }

        return (
          <Fragment>
            <ShareToSitesContent onClick={handleOpen} mt={mt} />
            <ShareToSitesModal
              isOpen={isOpen}
              isProcessing={isShareProcessing}
              onClose={handleClose}
              onConfirm={handleConfirm}
              onClear={handleClear}
              onChange={handleChange}
              selectedBlocPks={selectedBlocPks}
              shareableBlocs={props.blocsShareable.blocs}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default ShareToSites;

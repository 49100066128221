import { isValid } from "date-fns";
import { Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "react-radio-group";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import {
  dateParser,
  validateAndFormatDate
} from "pstat-anywhere/utils/dateHelpers";
import { userDisplay } from "pstat-anywhere/utils/userDisplay";
import { TrashIcon } from "pstat-design-system/Icons";
import AutocompleteTextInput from "pstat-design-system/inputs/AutocompleteTextInput";
import { LabelField } from "pstat-design-system/inputs/Labels";
import Radio from "pstat-design-system/inputs/Radio";
import ReferenceTagsAutocompleteInput from "pstat-design-system/inputs/ReferenceTagsAutocompleteInput";
import StandardsAndRegulationsInput from "pstat-design-system/inputs/StandardsAndRegulationsInput";
import Textarea from "pstat-design-system/inputs/Textarea";
import TextInput, {
  ClearableTextInput
} from "pstat-design-system/inputs/TextInput";
import { UserAutocompleteInput } from "pstat-design-system/inputs/UserAndUserGroupAutocompleteInput";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

const StyledText = styled.span`
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: ${themeGet("colors.nav.0")};
  margin-left: 10px;
  margin-right: 10px;
`;

export const FieldError = ({ iconSize, children }) => (
  <Text size="normal" color="messages.error">
    <StyledFontAwesomeIcon
      icon={["fal", "exclamation-triangle"]}
      size={iconSize ? iconSize : null}
    />{" "}
    {children}
  </Text>
);

const DisabledMessage = ({ disabledMessage }) => (
  <Text display="block" fontSize="13px" mb={1}>
    {disabledMessage}
  </Text>
);

export const InputFormElement = ({
  value,
  label,
  onChange,
  type,
  hasError,
  errors,
  autosize,
  required,
  name,
  id,
  dataTestid,
  onClear,
  maxLength,
  placeholder,
  ContainerProps,
  disabled,
  disabledMessage,
  ...otherProps
}) => {
  return (
    <InputFormElementContainer {...ContainerProps}>
      <LabelField name={label} required={required} htmlFor={id} />
      {disabled && disabledMessage && (
        <DisabledMessage disabledMessage={disabledMessage}></DisabledMessage>
      )}
      <ClearableTextInput
        onClear={onClear}
        id={id}
        error={hasError || !!errors}
        type={type}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        dataTestid={dataTestid}
        required={required}
        autosize={autosize}
        maxLength={maxLength}
        maxWidth={342}
        placeholder={placeholder}
        disabled={disabled}
        {...otherProps}
      />
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </InputFormElementContainer>
  );
};

export const TextareaFormElement = ({
  value,
  placeholder,
  label,
  onChange,
  type,
  errors,
  required,
  name,
  id,
  rows,
  dataTestid
}) => {
  return (
    <InputFormElementContainer>
      <LabelField name={label} required={required} />
      <Textarea
        id={id}
        placeholder={placeholder}
        error={!!errors}
        type={type}
        name={name || label.toLowerCase()}
        value={value}
        onChange={onChange}
        width={1}
        rows={rows}
        aria-label={label}
        data-testid={dataTestid}
      />
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </InputFormElementContainer>
  );
};

const RadioFormElementContainer = styled.div`
  margin-bottom: ${themeGet("space.4")}px;
`;

export const RadioGroupFormElement = ({
  header,
  children,
  name,
  onChange,
  selectedValue,
  required
}) => {
  return (
    <RadioFormElementContainer>
      <LabelField name={header} required={required} />
      <RadioGroup name={name} selectedValue={selectedValue} onChange={onChange}>
        {children}
      </RadioGroup>
    </RadioFormElementContainer>
  );
};

export const RadioFormElementWithInput = ({
  id,
  errors,
  radioLabel,
  inputValue,
  onInputChange,
  type,
  step,
  min,
  radioValue,
  dataTestid,
  disabled,
  disabledTooltipText
}) => {
  return (
    <RadioFormElementContainer>
      <Radio
        value={radioValue}
        renderLabel={() => (
          <Fragment>
            <TextInput
              id={id}
              error={!!errors}
              type={type}
              step={step}
              min={min}
              name={radioValue}
              value={inputValue}
              onChange={onInputChange}
              aria-label={radioLabel}
              width={"10ch"}
              mr={1}
              data-testid={dataTestid}
              disabled={disabled}
            />
            <StyledText>{radioLabel}</StyledText>
          </Fragment>
        )}
        disabled={disabled}
        disabledTooltipText={disabledTooltipText}
      />
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </RadioFormElementContainer>
  );
};

// TODO: fix DatePicker component so that it works
export const RadioFormElementWithDate = ({
  id,
  radioLabel,
  onInputChange,
  radioValue,
  inputValue,
  errors,
  disabled,
  disabledTooltipText
}) => {
  return (
    <RadioFormElementContainer>
      <Radio
        value={radioValue}
        renderLabel={() => (
          <Fragment>
            <StyledText>{radioLabel}</StyledText>
            <TextInput
              id={id}
              error={!!errors}
              type="date"
              name={radioValue}
              value={inputValue}
              onChange={onInputChange}
              aria-label={radioLabel}
              disabled={disabled}
            />
          </Fragment>
        )}
        disabled={disabled}
        disabledTooltipText={disabledTooltipText}
      />
      {/* <DatePicker name={name} onChange={onChange} /> */}
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </RadioFormElementContainer>
  );
};

export const DateInput = ({
  id,
  label,
  required,
  onChange,
  value,
  errors,
  name
}) => {
  return (
    <Fragment>
      <LabelField name={label} required={required} htmlFor={id} />
      <TextInput
        id={id}
        error={!!errors}
        type="date"
        name={name}
        value={value}
        onChange={onChange}
        aria-label={label}
        width={1}
        maxWidth={342}
      />
      {/* <DatePicker name={name} onChange={onChange} /> */}
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </Fragment>
  );
};

const InputFormElementContainer = styled.div`
  margin-bottom: ${({ mb = 15 }) => mb}px;
  display: ${({ display = "block" }) => display};
`;

export const FileUploadFormElement = ({
  fileID,
  chooseFile,
  uploadFile,
  filename,
  onFileChange,
  disabled,
  errors
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Button buttonStyle="tertiary" onClick={chooseFile} disabled={disabled}>
        Choose
      </Button>
      <input
        id={fileID}
        onChange={onFileChange}
        disabled={disabled}
        type="file"
        hidden
      />
      <UploadFileContainer>
        {filename ? (
          <Fragment>
            <StyledText>{filename}</StyledText>
            {uploadFile && (
              <Button
                buttonStyle="secondary"
                onClick={uploadFile}
                disabled={disabled}
              >
                {t("buttons.upload")}
              </Button>
            )}
          </Fragment>
        ) : (
          <Text color="nav.25" ml={1}>
            No file chosen
          </Text>
        )}
      </UploadFileContainer>
      <div>
        {errors &&
          errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
      </div>
    </Fragment>
  );
};

const UploadFileContainer = styled.div`
  display: inline-block;
`;

export const SingleSelectDropdownFormElement = props => {
  const { t } = useTranslation();

  const getSuggestionValue = suggestion => suggestion.name;

  const handleSuggestionSelected = selectedSuggestions => {
    if (selectedSuggestions.length === 0) {
      props.onClear();
    } else {
      const suggestion = selectedSuggestions[0];
      const event = {
        target: {
          value: suggestion.pk,
          valueName: getSuggestionValue(suggestion),
          name: props.name
        }
      };
      props.onChange(event);
    }
  };

  const getSelectedSuggestions = () =>
    props.options.filter(option => option.pk === props.value);

  const {
    label,
    required,
    errors,
    id,
    disabled,
    disabledMessage,
    options,
    suggestionsLimit,
    ...otherProps
  } = props;

  return (
    <InputFormElementContainer>
      <LabelField name={label} required={required} htmlFor={id} />
      {props.helpText && (
        <Text display="block" mb={3}>
          {props.helpText}
        </Text>
      )}
      {disabled && disabledMessage && (
        <DisabledMessage disabledMessage={disabledMessage}></DisabledMessage>
      )}
      {props.loading ? (
        <Fragment>
          <LoadingSpinner />
          <Text>{t("inputs.loading")}</Text>
        </Fragment>
      ) : (
        <AutocompleteTextInput
          selectedSuggestions={getSelectedSuggestions()}
          onSuggestionSelected={handleSuggestionSelected}
          suggestions={options}
          suggestionsLimit={suggestionsLimit}
          getSuggestionValue={getSuggestionValue}
          required={required}
          error={!!errors}
          id={id}
          disabled={disabled}
          {...otherProps}
        />
      )}
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </InputFormElementContainer>
  );
};

const getInitialSuggestion = user => {
  if (!user) {
    return [];
  }
  const suggestion = {
    type: "user",
    id: user.pk,
    first_name: user.firstName,
    last_name: user.lastName,
    display_name: userDisplay(user)
  };
  return [suggestion];
};

export const UserAutocompleteFormElement = props => {
  const [selectedSuggestions, setSelectedSuggestions] = useState(
    getInitialSuggestion(props.initialSelectedUser)
  );

  const getAuthorPk = suggestion => {
    const pkString = suggestion.id.split("_")[0];
    return parseInt(pkString, 10);
  };

  const handleSuggestionSelected = selectedSuggestions => {
    if (selectedSuggestions.length === 0) {
      props.onClear();
    } else {
      const suggestion = selectedSuggestions[0];
      const event = {
        target: {
          value: getAuthorPk(suggestion),
          name: props.name
        }
      };
      props.onChange(event);
    }
    setSelectedSuggestions(selectedSuggestions);
  };

  const { label, required, errors, mb, ...otherProps } = props;

  return (
    <InputFormElementContainer id={`${props.id}_section`} mb={mb}>
      <LabelField name={label} required={required} htmlFor={props.id} />
      <UserAutocompleteInput
        selectedSuggestions={selectedSuggestions}
        onSuggestionSelected={handleSuggestionSelected}
        required={required}
        error={!!errors}
        {...otherProps}
      />
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </InputFormElementContainer>
  );
};

const getInitialTagSuggestion = tags => {
  if (!tags) {
    return [];
  }
  return tags.map(tag => ({
    id: tag.pk,
    name: tag.name
  }));
};

export const ReferenceTagsAutocompleteFormElement = props => {
  const [selectedSuggestions, setSelectedSuggestions] = useState(
    getInitialTagSuggestion(props.initialSelectedTags)
  );
  const handleSuggestionSelected = selectedSuggestions => {
    if (selectedSuggestions.length === 0) {
      props.onClear();
    } else {
      const event = {
        target: {
          value: selectedSuggestions.map(getSuggestionValue),
          name: props.name
        }
      };
      props.onChange(event);
    }
    setSelectedSuggestions(selectedSuggestions);
  };

  const getSuggestionValue = suggestion => {
    if (suggestion.isNew) {
      return {
        name: suggestion.name
      };
    }
    return suggestion.id;
  };

  const { label, required, errors, ...otherProps } = props;

  return (
    <InputFormElementContainer>
      <LabelField name={label} required={required} />
      <ReferenceTagsAutocompleteInput
        selectedSuggestions={selectedSuggestions}
        onSuggestionSelected={handleSuggestionSelected}
        required={required}
        error={!!errors}
        {...otherProps}
      />
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </InputFormElementContainer>
  );
};

const AttachmentTextInputContainer = styled.div`
  @media (max-width: ${themeGet("breakpoints.0")}) {
    width: 100%;
  }
  @media (min-width: ${themeGet("breakpoints.0")}) {
    width: 342px;
  }
  display: inline-block;
  margin-right: ${themeGet("space.1")}px;
`;

const _AttachmentFormElement = ({
  attachmentPk,
  value,
  label,
  onChange,
  onClear,
  allowRename,
  handleRename,
  handleReplaceFile,
  handleDelete,
  handleInsertImage,
  renaming,
  replacing,
  isImage,
  editorLoading,
  isMissingFile,
  labels,
  fileUrl
}) => {
  const { t } = useTranslation();
  const documentLabel = labels.documentLabel.toLowerCase();
  const renameAttachmentID = `rename-attachment-${attachmentPk}`;
  const chooseFile = () => {
    // trigger file upload
    document.getElementById(renameAttachmentID).click();
  };
  const handleReplaceFileChange = event => {
    const file = event.target.files[0];
    handleReplaceFile(file);
  };
  return (
    <InputFormElementContainer>
      <LabelField name={label} required={false} url={fileUrl} />
      <div>
        {!isMissingFile && (
          <Fragment>
            <AttachmentTextInputContainer>
              <ClearableTextInput
                onClear={onClear}
                type="text"
                label={label}
                value={value}
                onChange={onChange}
                maxLength={200}
                dataTestid="attachment-name-input"
              />
            </AttachmentTextInputContainer>
            {isImage && (
              <Button
                buttonStyle="tertiary"
                style={{ marginRight: "5px" }}
                disabled={editorLoading}
                onClick={handleInsertImage}
              >
                {t("buttons.insertImage")}
              </Button>
            )}
            <Button
              buttonStyle="tertiary"
              style={{ marginRight: "5px" }}
              onClick={handleRename}
              disabled={!allowRename || replacing}
              processing={renaming}
            >
              {t("buttons.rename")}
            </Button>
            <Button
              buttonStyle="tertiary"
              style={{ marginRight: "5px" }}
              onClick={chooseFile}
              disabled={renaming}
              processing={replacing}
            >
              {t("buttons.replace")}
            </Button>
            <input
              id={renameAttachmentID}
              onChange={handleReplaceFileChange}
              disabled={replacing}
              type="file"
              hidden
            />
          </Fragment>
        )}
        <Button
          className="delete_attachment"
          buttonStyle="tertiary"
          disabled={renaming || replacing}
          onClick={handleDelete}
          fontSize="1"
        >
          <TrashIcon />
        </Button>
      </div>
      {isMissingFile && (
        <FieldError>
          {t("documentControl.create.attachments.missingFileError", {
            documentLabel
          })}
        </FieldError>
      )}
    </InputFormElementContainer>
  );
};
export const AttachmentFormElement = withLabelContext(_AttachmentFormElement);

export const StandardsAndRegulationsFormElement = ({
  value,
  label,
  required,
  onChange,
  onClear,
  errors,
  initialSelectedRegPks,
  ...otherProps
}) => {
  return (
    <InputFormElementContainer>
      <LabelField name={label} required={required} />
      <StandardsAndRegulationsInput
        required={required}
        error={!!errors}
        onChange={onChange}
        onClear={onClear}
        initialSelectedRegPks={initialSelectedRegPks}
        {...otherProps}
      />
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </InputFormElementContainer>
  );
};

export const MultipleDateFormElement = ({
  id,
  label,
  selectedDates,
  onDateSelected,
  required,
  errors,
  ...otherProps
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const getSuggestions = ({ value }) => {
    if (value) {
      if (isValid(dateParser(value))) {
        setSuggestions([value]);
      }
    }
  };
  const getSuggestionValue = suggestion => {
    return validateAndFormatDate({
      date: suggestion,
      formatString: "month-day-year"
    });
  };

  return (
    <InputFormElementContainer>
      <LabelField name={label} required={required} htmlFor={id} />
      <AutocompleteTextInput
        id={id}
        selectedSuggestions={selectedDates}
        onSuggestionSelected={onDateSelected}
        onSuggestionDeselected={onDateSelected}
        suggestions={suggestions}
        onSuggestionsFetchRequested={getSuggestions}
        getSuggestionPk={date => date}
        getSuggestionValue={getSuggestionValue}
        selectMultiple={true}
        type="date"
        {...otherProps}
      />
      {errors &&
        errors.map((error, i) => <FieldError key={i}>{error}</FieldError>)}
    </InputFormElementContainer>
  );
};

export const LocationsFormInput = ({
  value,
  name,
  options,
  identityBlocs,
  onChange,
  onClear,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const getSuggestionValue = suggestion => suggestion.pk;
  const getSuggestionName = suggestion => suggestion.name;
  const getSelectedSuggestions = () =>
    options.filter(option => value.indexOf(option.pk) > -1);

  const getIdentityBlocsForBlocTenants = bloc => {
    const tenantPks = bloc.tenants.map(tenant => tenant.pk);
    const blocTenantsIncludeIdentityBlocTenant = identityBloc => {
      const identityBlocTenantPk = identityBloc.tenants[0].pk;
      return tenantPks.indexOf(identityBlocTenantPk) > -1;
    };
    return identityBlocs.filter(blocTenantsIncludeIdentityBlocTenant);
  };

  const removeAlreadySelectedIdentityBlocs = (identityBlocs, selectedBlocs) => {
    const selectedBlocPks = selectedBlocs.map(bloc => bloc.pk);
    return identityBlocs.filter(
      bloc => selectedBlocPks.indexOf(bloc.pk) === -1
    );
  };

  const blocIsIdentityBloc = bloc => bloc.tenants.length === 1;

  const convertBlocsToIdentityBlocs = blocs => {
    return blocs.reduce((identityBlocs, bloc) => {
      if (blocIsIdentityBloc(bloc)) {
        identityBlocs.push(bloc);
      } else {
        const identityBlocsForBloc = getIdentityBlocsForBlocTenants(bloc);
        const newIdentityBlocs = removeAlreadySelectedIdentityBlocs(
          identityBlocsForBloc,
          blocs
        );
        identityBlocs = [...identityBlocs, ...newIdentityBlocs];
      }
      return identityBlocs;
    }, []);
  };

  const handleSuggestionSelected = selectedBlocs => {
    let identityBlocs;
    if (selectedBlocs.length === 0) {
      identityBlocs = [];
      onClear();
    } else {
      identityBlocs = convertBlocsToIdentityBlocs(selectedBlocs);
    }
    const event = {
      target: {
        value: identityBlocs.map(getSuggestionValue),
        name: name
      }
    };
    onChange(event);
  };

  const {
    errors,
    disabled,
    disabledMessage,
    inputLabel,
    hiddenTooltip,
    placeHolder,
    suggestionsMaxHeight,
    ...extraProps
  } = otherProps;

  const id = "eps_locations_input";
  return (
    <InputFormElementContainer>
      <Flex flexDirection="row">
        <LabelField
          name={inputLabel || t("systemDocumentTemplates.locationsInput.label")}
          required={false}
          htmlFor={id}
        />
        {hiddenTooltip || (
          <Fragment>
            <StyledFontAwesomeIcon
              icon={["fal", "question-circle"]}
              color="secondary.0"
              size="sm"
              data-tip
              data-for={`${id}-tooltip`}
              data-event={HOVER_EVENTS}
              data-event-off={HOVER_EVENTS_OFF}
              tabIndex="0"
            />
            <Tooltip name={`${id}-tooltip`}>
              {t("systemDocumentTemplates.locationsInput.helpText")}
            </Tooltip>
          </Fragment>
        )}
      </Flex>
      {disabled && disabledMessage && (
        <DisabledMessage disabledMessage={disabledMessage}></DisabledMessage>
      )}
      <AutocompleteTextInput
        placeholder={
          placeHolder || t("systemDocumentTemplates.locationsInput.placeholder")
        }
        selectedSuggestions={getSelectedSuggestions()}
        onSuggestionSelected={handleSuggestionSelected}
        onSuggestionDeselected={handleSuggestionSelected}
        suggestions={options}
        getSuggestionValue={getSuggestionName}
        required={false}
        error={!!errors}
        id={id}
        disabled={disabled}
        selectMultiple={true}
        shouldKeepSuggestionsOnSelect={() => true}
        suggestionsMaxHeight={suggestionsMaxHeight}
        {...extraProps}
      />
    </InputFormElementContainer>
  );
};

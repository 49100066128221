import graphql from "babel-plugin-relay/macro";

export const SharableBlocsQuery = graphql`
  query SharableBlocsQuery($documentPk: Int!) {
    blocsShareable(documentPk: $documentPk) {
      blocs {
        name
        pk
        tenants {
          pk
        }
      }
    }
  }
`;

export default SharableBlocsQuery;

import graphql from "babel-plugin-relay/macro";

const PendingDocumentsQuery = graphql`
  query PendingDocumentsQuery(
    $after: String
    $first: Int
    $sort: String
    $showAll: Boolean
    $search_query: String
    $pending_approver: Int
    $bloc: Int
  ) {
    pendingDocuments(
      after: $after
      first: $first
      sort: $sort
      showAll: $showAll
      q: $search_query
      pendingApproverId: $pending_approver
      blocId: $bloc
    ) {
      documents(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            name
            hasAttachments
            revisedFromParentStatus
            effectiveDate(formatted: true)
            restricted
            bloc {
              name
            }
            applicabilityType
            category {
              name
            }
            approvalWorkflow {
              name
              currentStep {
                name
                created
                approvers(onlyNeededApprovers: true) {
                  firstName
                  lastName
                  isDocumentOwner
                  hasApproved
                  email
                  notificationEmail
                }
              }
            }
            revisedFromParent
            documentsOfTemplate {
              documents {
                pk
                name
                bloc {
                  pk
                  name
                }
                applicabilityType
                category {
                  name
                }
                hasAttachments
                revisedFromParentStatus
                effectiveDate(formatted: true)
                restricted
                revisedFromParent
              }
            }
          }
        }
      }
      viewOnlyPks
    }
  }
`;

export default PendingDocumentsQuery;

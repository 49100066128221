import { Box, Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import system from "system-components";

import { ContentContainerFlex } from "pstat-anywhere/components/pages";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import { useTenant } from "pstat-anywhere/context_providers/TenantContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { InfoBanner } from "pstat-design-system/banner";
import ButtonLink from "pstat-design-system/ButtonLink";
import { FocusLink } from "pstat-design-system/Link";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import Panel, { ExpandablePanel } from "pstat-design-system/Panel";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import SideBarMenu, {
  ChangelogLink,
  SideBarLink
} from "pstat-design-system/SideBarMenu";
import { SortablePolicyTable } from "pstat-design-system/tables";
import { Text } from "pstat-design-system/text";

const AdminMenuPage = ({ adminMenu }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  let menuItems = [];
  if (adminMenu?.showStaffMenuLink) {
    menuItems.push({
      translationKey: "administration.sideBar.staffMenu",
      href: `${getRootUrl()}/staff_menu`,
      isExternal: true
    });
  }
  menuItems.push({
    translationKey: "administration.sideBar.adminMenu",
    href: `${getRootUrl()}/admin`,
    isActive: true
  });
  if (adminMenu?.showImportLink) {
    menuItems.push({
      translationKey: "administration.sideBar.importMenu",
      href: `${getRootUrl()}/i`,
      isExternal: true
    });
  }
  menuItems = menuItems.concat([
    "line",
    {
      translationKey: "administration.sideBar.supportHub",
      href: "https://hubcommunity.rldatix.com/",
      isExternal: true,
      target: "_blank"
    },
    {
      translationKey: "administration.sideBar.support",
      href: "https://rldatix-public.zendesk.com/hc/en-us",
      isExternal: true,
      target: "_blank"
    },
    {
      translationKey: "administration.sideBar.changelog",
      href: "https://grc-support.rldatix.com/hc/en-us/sections/12828883724572",
      // isExternal: true, //See #1600
      target: "_blank"
    }
  ]);
  if (adminMenu?.ahaIdeasPortalLink) {
    menuItems.push({
      translationKey: "administration.sideBar.ideasPortal",
      href: adminMenu?.ahaIdeasPortalLink,
      isExternal: true,
      target: "_blank"
    });
  }
  return (
    <ContentContainerFlex
      data-testid="admin-menu-container"
      pt={10}
      px={4}
      flexDirection={["column", "column", "row"]}
    >
      <Box width={[1, 1, 1 / 6]} pr={[0, 0, 8, 10]}>
        <SideBarMenu
          menuItems={menuItems}
          titleName={t("administration.title")}
          updateOnScroll={false}
          renderLink={(item, text, index) =>
            item.translationKey === "administration.sideBar.changelog" ? (
              <ChangelogLink
                key={index}
                active={item.isActive}
                light={item.isActive}
                href={item.href}
                noBorder={true}
                target={item.target}
                label={t("administration.sideBar.changelogLabel")}
              >
                <span>
                  {text}
                  {item.isExternal && (
                    <StyledFontAwesomeIcon icon={["far", "external-link"]} />
                  )}
                </span>
              </ChangelogLink>
            ) : (
              <SideBarLink
                key={index}
                active={item.isActive}
                light={item.isActive}
                href={item.href}
                noBorder={true}
                target={item.target}
              >
                <span>
                  {text}
                  {item.isExternal && (
                    <StyledFontAwesomeIcon icon={["far", "external-link"]} />
                  )}
                </span>
              </SideBarLink>
            )
          }
        />
      </Box>
      <Flex width={[1, 1, 5 / 6]} flexDirection="column">
        {adminMenu?.showSedEnableLink && <SEDInfoBanner />}
        <TopRowFlex>
          {!tenant.enableAllBlocs && (
            <Box flex="1" mr={[0, 0, 4]} mb={[0, 0, 6]}>
              <SiteDataPanel
                showBulkCopyAsNewLink={adminMenu?.showBulkCopyAsNewLink}
              />
            </Box>
          )}
          <Box flex="1" mr={[0, 0, 4]} mb={[0, 0, 6]}>
            <ConfigurationPanel
              enableAllBlocs={tenant.enableAllBlocs}
              showApiAccessTokensLink={adminMenu?.showApiAccessTokensLink}
              showCustomerConfigLink={adminMenu?.showCustomerConfigLink}
            />
          </Box>
          <Box flex="1" mb={[0, 0, 6]}>
            <SystemDocumentManagementPanel
              tenantEnableEnterprise={tenant.enableEnterprise}
              customerEnableEPS={
                tenant.customerSettings.enableEnterprisePolicySharing
              }
            />
          </Box>
        </TopRowFlex>
        <ReportsPanel enableAllBlocs={tenant.enableAllBlocs} />
        <ApplicabilityPanel />
        <CategoriesPanel
          totalCount={adminMenu?.totalCount}
          categories={adminMenu?.categoryCounts}
        />
        {adminMenu?.whitelist && (
          <WhitelistPanel whitelist={adminMenu?.whitelist} />
        )}
      </Flex>
    </ContentContainerFlex>
  );
};

export default AdminMenuPage;

const TopRowFlex = system({
  is: Flex,
  justifyContent: "space-between",
  flex: 1,
  flexWrap: "wrap",
  flexDirection: ["column", "column", "row"]
});
TopRowFlex.displayName = "TopRowFlex";

const AdminMenuLink = system({
  is: FocusLink,
  display: "block",
  mb: 2
});
AdminMenuLink.displayName = "AdminMenuLink";

const AdminMenuRouterLink = system({
  is: RouterFocusLink,
  display: "block",
  mb: 2
});
AdminMenuRouterLink.displayName = "AdminMenuRouterLink";

const FullHeightPanel = props => {
  return <Panel wrapperProps={{ mb: 0, height: "100%" }} {...props} />;
};

const SEDInfoBanner = () => {
  const { t } = useTranslation();
  const { documentLabel, documentLabelPlural } = useLabels();
  return (
    <InfoBanner width={1} id="SED_enable_banner">
      <div>
        <Text display="block" fontWeight="bold">
          {t("administration.SEDBanner.title", {
            documentLabelPlural: documentLabelPlural.toLowerCase()
          })}
        </Text>
        <Text display="block" mb={4}>
          {t("administration.SEDBanner.description", {
            documentLabel: documentLabel.toLowerCase()
          })}
        </Text>
        <ButtonLink
          href={`${getRootUrl()}/v2/to_legacy/?next=${getRootUrl()}/admin`}
          buttonStyle="secondary"
          mr={4}
          display="inline-block"
        >
          {t("administration.SEDBanner.enableLink")}
        </ButtonLink>
        <ButtonLink
          href="https://rldatix-public.zendesk.com/hc/en-us/articles/10341098389660-What-is-Scheduled-Effective-Date-"
          buttonStyle="tertiary"
          display="inline-block"
        >
          {t("administration.SEDBanner.learnMoreLink")}
        </ButtonLink>
      </div>
    </InfoBanner>
  );
};

const SiteDataPanel = ({ showBulkCopyAsNewLink }) => {
  const { t } = useTranslation();
  const { categoryLabelPlural, referencesLabelPlural } = useLabels();
  return (
    <FullHeightPanel
      id="site_data_panel"
      name={t("administration.siteData.heading")}
    >
      <AdminMenuLink id="account_manage_link" href={`${getRootUrl()}/account`}>
        {t("administration.siteData.users")}
      </AdminMenuLink>
      <AdminMenuLink href={`${getRootUrl()}/user_group`}>
        {t("administration.siteData.userGroups")}
      </AdminMenuLink>
      <AdminMenuLink href={`${getRootUrl()}/category`}>
        {titleCase(categoryLabelPlural)}
      </AdminMenuLink>
      <AdminMenuLink href={`${getRootUrl()}/approval/flow`}>
        {t("administration.siteData.approvalFlows")}
      </AdminMenuLink>
      <AdminMenuLink href={`${getRootUrl()}/tags/manage`}>
        {titleCase(referencesLabelPlural)}
      </AdminMenuLink>
      <AdminMenuLink href={`${getRootUrl()}/admin/mass_edit`}>
        {t("administration.siteData.bulkAdminOverride")}
      </AdminMenuLink>
      {showBulkCopyAsNewLink && (
        <AdminMenuLink href={`${getRootUrl()}/admin/bulk_copy_as_new`}>
          {t("administration.siteData.bulkCopyAsNew")}
        </AdminMenuLink>
      )}
      <AdminMenuLink
        id="implementation_dashboard_link"
        href={`${getRootUrl()}/implementation`}
      >
        {t("administration.siteData.implementation")}
      </AdminMenuLink>
    </FullHeightPanel>
  );
};

const ConfigurationPanel = ({
  enableAllBlocs,
  showCustomerConfigLink,
  showApiAccessTokensLink
}) => {
  const { t } = useTranslation();
  const { documentLabel } = useLabels();
  return (
    <FullHeightPanel
      id="configuration_panel"
      name={t("administration.configuration.heading")}
    >
      <AdminMenuLink href={`${getRootUrl()}/admin/login_token`}>
        {t("administration.configuration.guestAccessTokens")}
      </AdminMenuLink>
      {!enableAllBlocs && (
        <Fragment>
          <AdminMenuLink href={`${getRootUrl()}/admin/doc_settings`}>
            {t("administration.configuration.documentLayout", {
              documentLabel: titleCase(documentLabel)
            })}
          </AdminMenuLink>
          <AdminMenuLink href={`${getRootUrl()}/admin/template`}>
            {t("administration.configuration.siteDocumentTemplates", {
              documentLabel: titleCase(documentLabel)
            })}
          </AdminMenuLink>
        </Fragment>
      )}
      <AdminMenuLink href={`${getRootUrl()}/admin/site_config`}>
        {t("administration.configuration.siteConfig")}
      </AdminMenuLink>
      {!enableAllBlocs && (
        <Fragment>
          <AdminMenuLink href={`${getRootUrl()}/admin/active_directory`}>
            {t("administration.configuration.activeDirectory")}
          </AdminMenuLink>
          <AdminMenuLink href={`${getRootUrl()}/admin/single_signon`}>
            {t("administration.configuration.singleSignOn")}
          </AdminMenuLink>
          {showCustomerConfigLink && (
            <AdminMenuLink href={`${getRootUrl()}/customer/settings`}>
              {t("administration.configuration.customerConfig")}
            </AdminMenuLink>
          )}
        </Fragment>
      )}
      {showApiAccessTokensLink && (
        <AdminMenuLink href={`${getRootUrl()}/admin/api_access_tokens`}>
          {t("administration.configuration.apiAccessTokens")}
        </AdminMenuLink>
      )}
    </FullHeightPanel>
  );
};

const SystemDocumentManagementPanel = ({
  tenantEnableEnterprise,
  customerEnableEPS
}) => {
  const { t } = useTranslation();
  const { documentLabel } = useLabels();
  return (
    <FullHeightPanel
      id="system_document_management_panel"
      name={t("administration.systemDocumentManagement.heading", {
        documentLabel: titleCase(documentLabel)
      })}
    >
      <AdminMenuRouterLink to={`${getRootUrl()}/bloc`}>
        {t("administration.systemDocumentManagement.applicabilityGroups")}
      </AdminMenuRouterLink>
      {tenantEnableEnterprise && customerEnableEPS && (
        <AdminMenuRouterLink
          id="system_template_management"
          to={`${getRootUrl()}/template/manage`}
        >
          {t("administration.systemDocumentManagement.systemDocumentTemplates")}
        </AdminMenuRouterLink>
      )}
    </FullHeightPanel>
  );
};

const ReportsPanel = ({ enableAllBlocs }) => {
  const { t } = useTranslation();
  const { categoryLabel, documentLabel, documentLabelPlural } = useLabels();
  return (
    <Panel id="reports_panel" name={t("administration.reports.heading")}>
      <Flex wrap="wrap">
        <Box width={[1, 1, 1 / 2]}>
          <AdminMenuLink href={`${getRootUrl()}/policy_reports/active`}>
            {t("administration.reports.activeDocuments", {
              documentLabelPlural: titleCase(documentLabelPlural)
            })}
          </AdminMenuLink>
          <AdminMenuLink href={`${getRootUrl()}/policy_search/retired`}>
            {t("administration.reports.retiredDocuments", {
              documentLabelPlural: titleCase(documentLabelPlural)
            })}
          </AdminMenuLink>
          <AdminMenuRouterLink to={`${getRootUrl()}/policy_reports/expiring`}>
            {t("administration.reports.documentsDueForReview", {
              documentLabelPlural: titleCase(documentLabelPlural)
            })}
          </AdminMenuRouterLink>
          <AdminMenuLink
            href={`${getRootUrl()}/policy_reports/reviewed_revised`}
          >
            {t("administration.reports.reviewedAndRevisedDocuments", {
              documentLabelPlural: titleCase(documentLabelPlural)
            })}
          </AdminMenuLink>
          <AdminMenuLink
            href={`${getRootUrl()}/policy_reports/approval_durations`}
          >
            {t("administration.reports.documentApprovalTurnAroundTimeReport", {
              documentLabel: titleCase(documentLabel)
            })}
          </AdminMenuLink>
          <AdminMenuLink
            href={`${getRootUrl()}/recurring_acknowledgment/documents`}
          >
            {t("administration.reports.documentsWithRecurringAcks", {
              documentLabelPlural: titleCase(documentLabelPlural)
            })}
          </AdminMenuLink>
          <AdminMenuLink
            href={`${getRootUrl()}/recurring_acknowledgment/users`}
          >
            {t("administration.reports.usersWithRecurringAcks")}
          </AdminMenuLink>
          <AdminMenuLink
            href={`${getRootUrl()}/recurring_acknowledgment/user_groups`}
          >
            {t("administration.reports.userGroupsWithRecurringAcks")}
          </AdminMenuLink>
        </Box>
        <Box width={[1, 1, 1 / 2]}>
          <AdminMenuLink
            href={`${getRootUrl()}/policy_reports/approval_overview`}
          >
            {t("administration.reports.categoryApprovalTurnAroundTime", {
              categoryLabel: titleCase(categoryLabel)
            })}
          </AdminMenuLink>
          {!enableAllBlocs && (
            <AdminMenuLink href={`${getRootUrl()}/backup`}>
              {t("administration.reports.downloadPDFBackup")}
            </AdminMenuLink>
          )}
          <AdminMenuLink href={`${getRootUrl()}/policy_reports/hyperlinks/csv`}>
            {t("administration.reports.downloadHyperlinkCSV")}
          </AdminMenuLink>
          <AdminMenuLink href={`${getRootUrl()}/policy_reports/attachment/csv`}>
            {t("administration.reports.downloadAttachmentCSV")}
          </AdminMenuLink>
          <AdminMenuLink
            href={`${getRootUrl()}/admin_reports/import_status_by_applicability/customer`}
          >
            {t("administration.reports.conversionStatusByApplicabilityGroup")}
          </AdminMenuLink>
          <AdminMenuRouterLink
            to={`${getRootUrl()}/policy_reports/awaiting_activation/pending/all`}
          >
            {t("administration.reports.pendingDocumentsReport", {
              documentLabelPlural: titleCase(documentLabelPlural)
            })}
          </AdminMenuRouterLink>
          <AdminMenuLink href={`${getRootUrl()}/policy_reports/slice_in_time/`}>
            {t("administration.reports.sliceInTime")}
          </AdminMenuLink>
        </Box>
      </Flex>
    </Panel>
  );
};

const ApplicabilityPanel = () => {
  const { t } = useTranslation();
  return (
    <ExpandablePanel
      id="applicability_panel"
      headerText={t("administration.applicability.heading")}
    >
      <Flex justifyContent="center">
        <FocusLink href={`${getRootUrl()}/admin/applicability_network`}>
          <ApplicabilityImg
            src="/bloc/visualization/?local=true"
            alt={t("administration.applicability.altText")}
            title={t("administration.applicability.title")}
          />
        </FocusLink>
      </Flex>
    </ExpandablePanel>
  );
};

const ApplicabilityImg = system({
  is: "img",
  maxWidth: "100%"
});

const CategoriesPanel = ({ totalCount, categories }) => {
  const { t } = useTranslation();
  const { documentLabel, documentLabelPlural } = useLabels();
  let label = documentLabelPlural;
  if (totalCount === 1) {
    label = documentLabel;
  }
  return (
    <Panel
      id="categories_panel"
      name={t("administration.categories.heading", {
        totalCount,
        documentLabel: titleCase(label)
      })}
    >
      {categories ? (
        <SortablePolicyTable
          tableType="admin_menu_categories"
          policies={categories}
          noSort={true}
        />
      ) : (
        <Flex justifyContent="center">
          <LoadingSpinner size={100} />
        </Flex>
      )}
    </Panel>
  );
};

const WhitelistPanel = ({ whitelist }) => {
  const { t } = useTranslation();
  return (
    <Panel
      id="whitelist_panel"
      name={t("administration.whitelist.heading", {
        totalCount: whitelist.length
      })}
    >
      <SortablePolicyTable
        tableType="admin_menu_whitelist"
        policies={whitelist}
        noSort={true}
      />
    </Panel>
  );
};

import { getUrl, isDevMode } from "./urls";

const CSRF_COOKIE_NAME = "policystat_csrftoken";
export const CSRF_TOKEN_NAME = "csrfmiddlewaretoken";

const getCookies = () => {
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    return cookies.map(cookie => cookie.trim());
  }
  return [];
};

export const getCSRFToken = () => {
  const cookies = getCookies();
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    if (
      cookie.substring(0, CSRF_COOKIE_NAME.length + 1) ===
      CSRF_COOKIE_NAME + "="
    ) {
      return decodeURIComponent(cookie.substring(CSRF_COOKIE_NAME.length + 1));
    }
  }
  return null;
};

const customFetch = (urlPath, options) => {
  let credentials;

  if (!options) {
    options = {};
  }

  const uri = getUrl(urlPath);
  if (isDevMode()) {
    credentials = "include";
  } else {
    credentials = "same-origin";
  }

  const defaultOptions = {
    method: "GET",
    credentials: credentials,
    headers: {
      "content-type": "application/json"
    }
  };

  const mergedOptions = { ...defaultOptions, ...options };

  return fetch(uri, mergedOptions);
};

export default customFetch;

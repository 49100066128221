/**
 * @flow
 * @relayHash ff7369c7075cad00b0c2d9b0044114d6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SharableBlocsQueryVariables = {|
  documentPk: number
|};
export type SharableBlocsQueryResponse = {|
  +blocsShareable: ?{|
    +blocs: ?$ReadOnlyArray<?{|
      +name: string,
      +pk: ?number,
      +tenants: ?$ReadOnlyArray<?{|
        +pk: ?number
      |}>,
    |}>
  |}
|};
export type SharableBlocsQuery = {|
  variables: SharableBlocsQueryVariables,
  response: SharableBlocsQueryResponse,
|};
*/


/*
query SharableBlocsQuery(
  $documentPk: Int!
) {
  blocsShareable(documentPk: $documentPk) {
    blocs {
      name
      pk
      tenants {
        pk
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "documentPk",
    "variableName": "documentPk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SharableBlocsQuery",
  "id": "0ecf18320c9b6d57eaa4f81c3b4053fd",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SharableBlocsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocsShareable",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SharableBlocsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocsShareable",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v3,
                  v4
                ]
              },
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query SharableBlocsQuery(\n  $documentPk: Int!\n) {\n  blocsShareable(documentPk: $documentPk) {\n    blocs {\n      name\n      pk\n      tenants {\n        pk\n        id\n      }\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = 'b2e55365cb8cb1ea863da93b744c38d0';
module.exports = node;

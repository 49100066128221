import { Box, Flex } from "grid-styled";
import mailToLink from "mailto-link";
import { Component, Fragment } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Media from "react-media";

import { printDocument } from "../print/PrintMutation";

import emailBody from "./emailBody";

import ShareToSites from "pstat-anywhere/components/document_control/view_policy/share/share_to_sites/ShareToSites";
import TokenAccess from "pstat-anywhere/components/document_control/view_policy/share/token_access/TokenAccess";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { PermissionDenied403Error } from "pstat-anywhere/errors";
import { environment } from "pstat-anywhere/relay";
import theme from "pstat-anywhere/themes/policystat/theme";
import { isActive } from "pstat-anywhere/utils/document";
import {
  POLICY_ACTIONS,
  triggerPolicyEvent
} from "pstat-anywhere/utils/googleTagManager";
import CopyLink from "pstat-design-system/inputs/CopyLink";
import { createErrorMessage } from "pstat-design-system/message/manageMessages";
import Modal from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { H3, Text } from "pstat-design-system/text";



const EmailPDF = ({ onSend, loading, mt }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <H3 color="nav.0" fontWeight={theme.fontWeights.normal} mt={mt}>
        {t("documentControl.view.share.modal.emailPDF.title")}
      </H3>
      <Text py={3}>
        {t("documentControl.view.share.modal.emailPDF.description")}
      </Text>
      <Button buttonStyle="secondary" onClick={onSend} processing={loading}>
        {t("documentControl.view.share.modal.emailPDF.sendEmail")}
      </Button>
    </Fragment>
  );
};

export const RSSFeed = ({ url, mt }) => {
  const { t } = useTranslation();

  if (!url) {
    return <Fragment></Fragment>;
  }
  return (
    <Fragment>
      <H3 color="nav.0" fontWeight={theme.fontWeights.normal} mt={mt}>
        {t("documentControl.view.share.modal.rssFeed.title")}
      </H3>
      <Text py={3}>
        {t("documentControl.view.share.modal.rssFeed.description")}
      </Text>
      <CopyLink url={url} fieldsetWidth="100%" />
    </Fragment>
  );
};

const GuestAccessLink = ({ url, documentLabelPlural }) => {
  const { t } = useTranslation();
  documentLabelPlural = documentLabelPlural.toLowerCase();
  return (
    <Fragment>
      <H3 color="nav.0" fontWeight={theme.fontWeights.normal}>
        {t("documentControl.view.share.modal.guestAccessLink.title")}
      </H3>
      <Text py={3}>
        {t("documentControl.view.share.modal.guestAccessLink.description", {
          documentLabelPlural
        })}
      </Text>
      <CopyLink url={url} fieldsetWidth="100%" />
    </Fragment>
  );
};

const DesktopContent = props => (
  <Fragment>
    <Flex px={5} pt={4}>
      {props.guestAccessLink && (
        <Flex flexDirection="column" alignItems="center" px={2} py={6} flex="1">
          <GuestAccessLink
            url={props.guestAccessLink}
            documentLabelPlural={props.labels.documentLabelPlural}
          />
        </Flex>
      )}
      {props.includeRss && (
        <Flex flexDirection="column" alignItems="center" px={2} py={6} flex="1">
          <RSSFeed url={props.document.rssFeedUrl} />
        </Flex>
      )}
    </Flex>
    <Flex px={5} pt={4}>
      <Flex flexDirection="column" alignItems="center" px={2} py={6} flex="1">
        <EmailPDF onSend={props.onSendEmail} loading={props.loading} />
      </Flex>
      <Flex flexDirection="column" alignItems="center" px={2} py={6} flex="1">
        <ShareToSites
          documentPk={props.document.pk}
          systemDocumentTemplatePk={
            props.document.systemDocumentTemplateExtension?.pk
          }
          environment={props.environment}
        />
      </Flex>
    </Flex>
    {props.showReadOnlyTokens && (
      <TokenAccess
        isDesktop={true}
        tokens={props.documentAccessTokens}
        documentPk={props.document.pk}
        location={props.location}
      />
    )}
  </Fragment>
);

const MobileContent = props => (
  <Fragment>
    <Box px={5} py={4}>
      {props.guestAccessLink && (
        <GuestAccessLink
          url={props.guestAccessLink}
          documentLabelPlural={props.labels.documentLabelPlural}
        />
      )}
      <EmailPDF
        onSend={props.onSendEmail}
        loading={props.loading}
        mt={props.guestAccessLink ? 6 : 0}
      />
      <ShareToSites
        documentPk={props.document.pk}
        systemDocumentTemplatePk={
          props.document.systemDocumentTemplateExtension?.pk
        }
        mt={6}
        environment={props.environment}
      />
      {props.includeRss && <RSSFeed url={props.document.rssFeedUrl} mt={6} />}
    </Box>
    {props.showReadOnlyTokens && (
      <TokenAccess
        isDesktop={false}
        tokens={props.documentAccessTokens}
        documentPk={props.document.pk}
        location={props.location}
      />
    )}
  </Fragment>
);

class ShareModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null
    };
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.error && this.state.error?.statusCode === 403) {
      throw new PermissionDenied403Error(null, {
        tenantsToRedirect: this.state.error.tenantsToRedirect,
        documentPk: this.props.document.pk
      });
    }
  }

  prepPdfForEmail = () => {
    this.setState({
      loading: true
    });
    printDocument(
      environment,
      this.props.document,
      true,
      false,
      false,
      false,
      this.mutationComplete,
      this.props.displayChangesDiff
    );
  };

  mutationComplete = response => {
    const { t, onClose, document, labels } = this.props;
    this.setState({
      loading: false
    });
    const result = response.printDocument;
    if (result.ok) {
      if (!this.props.isPreview) {
        const document = this.props.document;
        triggerPolicyEvent(
          POLICY_ACTIONS.EMAIL,
          document?.pk,
          document?.name,
          document?.category?.pk,
          document?.category?.name
        );
      }
      const { url } = result;

      // TODO: probably better to create this email text on the backend
      const body = emailBody({
        url,
        document: document,
        documentLabel: labels.documentLabel.toLowerCase(),
        t
      });

      // compose email mailto link

      const href = mailToLink({
        body
      });

      window.open(href);
      onClose();
    } else if (result.error.statusCode === 403) {
      this.setState({
        error: {
          statusCode: 403,
          tenantsToRedirect: result?.tenantsToRedirect
        }
      });
    } else {
      createErrorMessage(t("documentControl.view.share.modal.errorMessage"));
      onClose();
    }
  };

  render() {
    const {
      t,
      isOpen,
      onAfterOpen,
      onClose,
      document,
      documentAccessTokens,
      guestAccessLink,
      labels,
      viewer,
      includeRss,
      location,
      canEditDocument,
      env = environment //pass mockEnv otherwise use default env
    } = this.props;
    const { loading } = this.state;
    const documentLabelTitleCase =
      labels.documentLabel.charAt(0).toUpperCase() +
      labels.documentLabel.substr(1).toLowerCase();

    const showReadOnlyTokens = isActive(document.status) && canEditDocument;
    return (
      <Modal
        contentLabel={t("documentControl.view.share.modal.contentLabel", {
          documentLabel: documentLabelTitleCase
        })}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onClose}
      >
        <Flex flexDirection="column">
          <Media query={{ minWidth: theme.breakpoints[1] }}>
            {isDesktop =>
              isDesktop ? (
                <DesktopContent
                  document={document}
                  documentAccessTokens={documentAccessTokens}
                  onSendEmail={this.prepPdfForEmail}
                  guestAccessLink={guestAccessLink}
                  labels={labels}
                  loading={loading}
                  viewer={viewer}
                  includeRss={includeRss}
                  location={location}
                  showReadOnlyTokens={showReadOnlyTokens}
                  environment={env}
                />
              ) : (
                <MobileContent
                  document={document}
                  documentAccessTokens={documentAccessTokens}
                  onSendEmail={this.prepPdfForEmail}
                  guestAccessLink={guestAccessLink}
                  labels={labels}
                  loading={loading}
                  viewer={viewer}
                  includeRss={includeRss}
                  location={location}
                  showReadOnlyTokens={showReadOnlyTokens}
                  environment={env}
                />
              )
            }
          </Media>
        </Flex>
      </Modal>
    );
  }
}

export default withTranslation()(withLabelContext(ShareModal));

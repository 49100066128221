/**
 * @flow
 * @relayHash 2156e536f0b953cbe65b67251d70905f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type HomeContentQueriesDocumentsToReviewQueryVariables = {||};
export type HomeContentQueriesDocumentsToReviewQueryResponse = {|
  +documentsToReviewHome: ?{|
    +documents: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: string,
      +bloc: {|
        +pk: ?number,
        +name: string,
      |},
      +applicabilityType: ?ApplicabilityType,
      +category: {|
        +name: string
      |},
      +expirationDate: ?string,
      +restricted: boolean,
      +documentsOfTemplate: ?{|
        +documents: ?$ReadOnlyArray<?{|
          +pk: ?number,
          +name: string,
          +bloc: {|
            +pk: ?number,
            +name: string,
          |},
          +applicabilityType: ?ApplicabilityType,
          +category: {|
            +name: string
          |},
          +expirationDate: ?string,
        |}>
      |},
    |}>,
    +totalCount: ?number,
    +buckets: ?any,
    +counts: ?{|
      +templateCount: ?number
    |},
  |}
|};
export type HomeContentQueriesDocumentsToReviewQuery = {|
  variables: HomeContentQueriesDocumentsToReviewQueryVariables,
  response: HomeContentQueriesDocumentsToReviewQueryResponse,
|};
*/


/*
query HomeContentQueriesDocumentsToReviewQuery {
  documentsToReviewHome {
    documents {
      pk
      name
      bloc {
        pk
        name
        id
      }
      applicabilityType
      category {
        name
        id
      }
      expirationDate
      restricted
      documentsOfTemplate {
        documents {
          pk
          name
          bloc {
            pk
            name
            id
          }
          applicabilityType
          category {
            name
            id
          }
          expirationDate
          id
        }
      }
      id
    }
    totalCount
    buckets
    counts {
      templateCount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "bloc",
  "storageKey": null,
  "args": null,
  "concreteType": "BlocType",
  "plural": false,
  "selections": [
    v0,
    v1
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": [
    v1
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buckets",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "counts",
  "storageKey": null,
  "args": null,
  "concreteType": "DueForReviewCounts",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "templateCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "bloc",
  "storageKey": null,
  "args": null,
  "concreteType": "BlocType",
  "plural": false,
  "selections": [
    v0,
    v1,
    v10
  ]
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": [
    v1,
    v10
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "HomeContentQueriesDocumentsToReviewQuery",
  "id": "ab969ff0fac8e6d57acf166846ffa364",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "HomeContentQueriesDocumentsToReviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentsToReviewHome",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentsToReviewHomePageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": true,
            "selections": [
              v0,
              v1,
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "documentsOfTemplate",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentsOfTemplateType",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "documents",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": true,
                    "selections": [
                      v0,
                      v1,
                      v2,
                      v3,
                      v4,
                      v5
                    ]
                  }
                ]
              }
            ]
          },
          v7,
          v8,
          v9
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomeContentQueriesDocumentsToReviewQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentsToReviewHome",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentsToReviewHomePageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": true,
            "selections": [
              v0,
              v1,
              v11,
              v3,
              v12,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "documentsOfTemplate",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentsOfTemplateType",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "documents",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": true,
                    "selections": [
                      v0,
                      v1,
                      v11,
                      v3,
                      v12,
                      v5,
                      v10
                    ]
                  }
                ]
              },
              v10
            ]
          },
          v7,
          v8,
          v9
        ]
      }
    ]
  }
};
})();
node.text = "query HomeContentQueriesDocumentsToReviewQuery {\n  documentsToReviewHome {\n    documents {\n      pk\n      name\n      bloc {\n        pk\n        name\n        id\n      }\n      applicabilityType\n      category {\n        name\n        id\n      }\n      expirationDate\n      restricted\n      documentsOfTemplate {\n        documents {\n          pk\n          name\n          bloc {\n            pk\n            name\n            id\n          }\n          applicabilityType\n          category {\n            name\n            id\n          }\n          expirationDate\n          id\n        }\n      }\n      id\n    }\n    totalCount\n    buckets\n    counts {\n      templateCount\n    }\n  }\n}\n";
(node/*: any*/).hash = 'eb1373da4cca6b78229c74876324803a';
module.exports = node;

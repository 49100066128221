import { Component, Fragment, forwardRef } from "react";
import Autosuggest from "react-autosuggest";
import { withTranslation } from "react-i18next";
import { themeGet } from "styled-system";
import system from "system-components";

import MultiSelectInput from "../MultiSelectInput";
import { TextInputWithIcon } from "../TextInput";

import { Text } from "pstat-design-system/text";
import { HiddenDiv } from "pstat-design-system/inputs/AutosizeInput";
import theme from "pstat-anywhere/themes/policystat/theme";
import colors from "pstat-anywhere/themes/policystat/colors";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

export const INPUT_FOCUSED = "input-focused";

const StyledAutosuggestContainer = system(
  {
    width: ["100%", 342],
    maxWidth: "100%",
    display: "inline-block"
  },
  "space",
  "width"
).extend`
  & .react-autosuggest__container {
    position: relative;
  }
`;

const StyledSuggestionsContainer = system({
  bg: "nav.100",
  borderRadius: 1,
  color: "nav.0",
  fontFamily: theme.fontFamily,
  mt: 1,
  position: "absolute"
}).extend`
  left: 0;
  right: 0;
  z-index: ${themeGet("layers.activeFloatingUi", 150)};
  &.react-autosuggest__suggestions-container {
      max-height: ${props => props.suggestionsMaxHeight || "130px"};
      overflow-y: auto;
    display: none;
  }
  &.react-autosuggest__suggestions-container--open {
    border: solid 1px ${themeGet("colors.nav.80")};
    display: block;
  }
`;

const StyledSuggestion = system(
  {
    pl: 1,
    py: 2
  },
  "color"
).extend`
  cursor: pointer;
  ${props => props.isHighlighted && `background-color: ${colors.nav["95"]}`}
`;

const DropdownTextInput = forwardRef((props, ref) => {
  let showDropdownIcon = true;
  let icon = ["far", "chevron-down"];
  if (props.onClear && props.value && props.value !== "" && !props.disabled) {
    showDropdownIcon = false;
    icon = ["far", "times"];
  }
  return (
    <TextInputWithIcon
      icon={icon}
      onClick={props.onClear && props.onClear}
      side="right"
      {...props}
      innerRef={ref}
      iconClickable={!showDropdownIcon}
    />
  );
});

const BadgedSelectInputComponent = system({
  is: DropdownTextInput,
  border: 1,
  borderColor: "primary.0",
  borderRadius: 3
}).extend`
  ${props =>
    !props.isValid &&
    `
    border: solid 5px ${colors.messages["error"]};
  `}
`;

const SingleSelectInputComponent = system({
  is: DropdownTextInput
}).extend`
  ${props =>
    !props.isValid &&
    `
    border: solid 2px ${colors.messages["error"]};
  `}
`;

const SingleSelectInput = ({ isBadgedInput, ...otherProps }) => {
  if (isBadgedInput) {
    return <BadgedSelectInputComponent {...otherProps} />;
  } else {
    return <SingleSelectInputComponent {...otherProps} />;
  }
};

class AutocompleteTextInput extends Component {
  constructor(props) {
    super(props);

    let value = "";
    if (props.selectedSuggestions.length > 0 && !props.selectMultiple) {
      value = props.getSuggestionValue(props.selectedSuggestions[0]);
    }

    this.state = {
      value: value,
      isValid: false,
      inputWidth: props.minimumWidth,
      // internal suggestion state only used if using defaultSuggestionFilter
      internalSuggestions: this.props.suggestions
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getSuggestionValue = suggestion => {
    return this.props.selectMultiple
      ? ""
      : this.props.getSuggestionValue(suggestion);
  };

  onSuggestionsClearRequested = () => {
    // TODO: this state isn't in this component, so this wont do anything
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionIndex }) => {
    if (this.props.standardsAndRegs) {
      this.props.onSuggestionSelected(suggestion, suggestionIndex);
      return;
    }
    if (this.props.selectMultiple) {
      const currentlySelectedSuggestions = this.props.selectedSuggestions;
      const currentlySelectedSuggestionPks = this.getSuggestionPks(
        currentlySelectedSuggestions,
        true
      ); // filtering null pks for adding many new values
      if (
        currentlySelectedSuggestionPks.indexOf(
          this.getSuggestionPk(suggestion)
        ) === -1
      ) {
        this.props.onSuggestionSelected([
          ...currentlySelectedSuggestions,
          suggestion
        ]);
      }
    } else {
      this.props.onSuggestionSelected([suggestion]);
    }
  };

  handleOnBlur = () => {
    if (this.props.selectedSuggestions.length === 1) {
      const firstSuggestion = this.props.selectedSuggestions[0];
      this.setState({ value: this.props.getSuggestionValue(firstSuggestion) });
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  onClear = event => {
    this.setState({ value: "" });
    if (!this.props.selectMultiple) {
      this.props.onSuggestionSelected([]);
    }
  };

  getSuggestionPk = suggestion => {
    if (this.props.getSuggestionPk) {
      return this.props.getSuggestionPk(suggestion);
    }
    const { pk, id } = suggestion;
    return pk || id;
  };

  getSuggestionPks = (suggestions, filterNull = false) => {
    const pks = suggestions.map(suggestion => this.getSuggestionPk(suggestion));
    return filterNull ? pks.filter(item => item) : pks;
  };

  storeInputReference = autosuggest => {
    // this is how react-autosuggests recommends to get the ref to the input
    // https://github.com/moroshko/react-autosuggest/blob/master/FAQ.md#how-do-i-get-the-input-element
    if (autosuggest !== null) {
      this.textInput = autosuggest.input;
    }
  };

  checkInputValidation = () => {
    let isValid = false;
    if (this.textInput) {
      isValid = this.textInput.validity.valid;
    }
    if (this.props.required) {
      isValid = isValid && this.props.selectedSuggestions.length >= 1;
    }
    this.setState({ isValid: isValid });
  };

  componentDidMount = () => {
    this.mounted = true;
    this.checkInputValidation();
    this.updateInputWidth();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.selectedSuggestions !== this.props.selectedSuggestions) {
      this.checkInputValidation();
    }

    if (prevState.value !== this.state.value) {
      this.updateInputWidth();
    }
  };

  renderInputComponent = inputProps => {
    const { ref, ...otherProps } = inputProps;
    const onBlur = event => {
      otherProps.onBlur(event);
      this.handleOnBlur(event);
    };

    return (
      <Fragment>
        {this.props.selectMultiple ? (
          <MultiSelectInput
            {...otherProps}
            inputRef={ref}
            selectedItems={this.props.selectedSuggestions}
            getItemValue={this.props.getSuggestionValue}
            getItemPk={this.props.getSuggestionPk || this.getSuggestionPk}
            renderItemBadge={this.props.renderSuggestionBadge}
            onItemDeselected={this.props.onSuggestionDeselected}
            disabled={this.props.disabled}
            isValid={this.state.isValid}
            required={
              this.props.required && this.props.selectedSuggestions.length === 0
            }
            inputIcon={this.props.inputIcon}
          />
        ) : (
          <SingleSelectInput
            {...otherProps}
            innerRef={ref}
            disabled={this.props.disabled}
            isValid={this.state.isValid}
            required={this.props.required}
            isBadgedInput={this.props.isBadgedInput}
            onBlur={onBlur}
          />
        )}
      </Fragment>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children }) => (
    <StyledSuggestionsContainer
      {...containerProps}
      suggestionsMaxHeight={this.props.suggestionsMaxHeight}
    >
      {children}
    </StyledSuggestionsContainer>
  );

  renderSuggestion = (suggestion, { isHighlighted }) => {
    const selectedSuggestionPks = this.getSuggestionPks(
      this.props.selectedSuggestions
    );
    const isSelected =
      selectedSuggestionPks.indexOf(this.getSuggestionPk(suggestion)) > -1;
    if (this.props.standardsAndRegs) {
      if (!suggestion.isVisible) {
        return null;
      }
      return (
        <StyledSuggestion
          pl={`${suggestion.level * 20}px`}
          isHighlighted={isHighlighted}
        >
          {suggestion.isRegulation ? (
            <Fragment>
              {isSelected ? (
                <Fragment>
                  <StyledFontAwesomeIcon
                    icon={["far", "check"]}
                    color="secondary.0"
                    mx={3}
                  />
                  <Text>{this.props.getSuggestionValue(suggestion)}</Text>
                </Fragment>
              ) : (
                <Text ml={11}>{this.props.getSuggestionValue(suggestion)}</Text>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {suggestion.expanded ? (
                <StyledFontAwesomeIcon
                  icon={["far", "minus"]}
                  color="secondary.0"
                  size="lg"
                  mx={3}
                />
              ) : (
                <StyledFontAwesomeIcon
                  icon={["far", "plus"]}
                  color="secondary.0"
                  size="lg"
                  mx={3}
                />
              )}
              <Text>{this.props.getSuggestionValue(suggestion)}</Text>
            </Fragment>
          )}
        </StyledSuggestion>
      );
    }
    if (suggestion.isNew) {
      return (
        <StyledSuggestion
          pl={7}
          isHighlighted={isHighlighted}
          color="secondary.0"
        >
          {this.props.t("inputs.referenceInput", {
            tagName: this.props.getSuggestionValue(suggestion)
          })}
        </StyledSuggestion>
      );
    }
    return (
      <Fragment>
        {isSelected ? (
          <StyledSuggestion isHighlighted={isHighlighted}>
            <StyledFontAwesomeIcon
              icon={["far", "check"]}
              color="secondary.0"
            />
            {this.props.getSuggestionValue(suggestion)}
          </StyledSuggestion>
        ) : (
          <StyledSuggestion pl={7} isHighlighted={isHighlighted}>
            {this.props.getSuggestionValue(suggestion)}
          </StyledSuggestion>
        )}
      </Fragment>
    );
  };

  sizerRef = el => {
    this.sizer = el;
  };

  getSizerWidth = () => {
    return this.sizer ? this.sizer.scrollWidth : 0;
  };

  updateInputWidth = () => {
    if (!this.mounted || !this.sizer || !this.getSizerWidth() === "undefined") {
      return;
    }

    let newInputWidth;
    newInputWidth = this.getSizerWidth() + this.props.margin;

    if (newInputWidth < this.props.minimumWidth) {
      newInputWidth = this.props.minimumWidth;
    }
    if (newInputWidth > this.props.maximumWidth) {
      newInputWidth = this.props.maximumWidth;
    }

    if (newInputWidth !== this.state.inputWidth) {
      this.setState({ inputWidth: newInputWidth });
    }
    return newInputWidth;
  };

  defaultSuggestionFilter = ({ value, reason }) => {
    const { suggestions, getSuggestionValue, suggestionsLimit } = this.props;
    let filteredSuggestions = filterSuggestions(
      value,
      suggestions,
      getSuggestionValue,
      reason
    );
    if (suggestionsLimit) {
      filteredSuggestions = filteredSuggestions.slice(0, suggestionsLimit);
    }
    this.setState({ internalSuggestions: filteredSuggestions });
  };

  render() {
    const containerProps = {
      width: `${this.state.inputWidth}px`,
      ...this.props.containerProps
    };

    return (
      <Fragment>
        <StyledAutosuggestContainer {...containerProps}>
          <Autosuggest
            suggestions={
              this.props.onSuggestionsFetchRequested
                ? this.props.suggestions
                : this.state.internalSuggestions
            }
            onSuggestionsFetchRequested={
              this.props.onSuggestionsFetchRequested ||
              this.defaultSuggestionFilter
            }
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            focusInputOnSuggestionClick={this.props.standardsAndRegs}
            getSuggestionValue={this.getSuggestionValue}
            shouldRenderSuggestions={() => true}
            renderSuggestion={this.renderSuggestion}
            renderInputComponent={this.renderInputComponent}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            inputProps={{
              id: this.props.id,
              value: this.state.value,
              placeholder: this.props.placeholder,
              name: this.props.name,
              onChange: this.onChange,
              onClear: this.onClear,
              type: this.props.type || "text"
            }}
            ref={this.storeInputReference}
            shouldKeepSuggestionsOnSelect={
              this.props.shouldKeepSuggestionsOnSelect
            }
          />
        </StyledAutosuggestContainer>
        <HiddenDiv innerRef={this.sizerRef}>{this.state.value}</HiddenDiv>
      </Fragment>
    );
  }
}

AutocompleteTextInput.defaultProps = {
  margin: 40,
  minimumWidth: 342,
  maximumWidth: 750
};

export default withTranslation()(AutocompleteTextInput);

const filterSuggestions = (searchValue, options, getOptionValue, reason) => {
  const trimmedSearchValue = searchValue.trim().toLowerCase();

  let suggestions = null;
  if (trimmedSearchValue.length === 0 || reason === INPUT_FOCUSED) {
    suggestions = options;
  } else {
    suggestions = options.filter(option => {
      const optionValue = getOptionValue(option).toLowerCase();
      return optionValue.indexOf(trimmedSearchValue) > -1;
    });
  }
  return suggestions;
};

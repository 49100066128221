import { Box, Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

const LocalizePolicy = ({
  canLocalize,
  isTemplate,
  onLocalize,
  convertingToTemplate,
  loadingBlocs
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  if (!(canLocalize || isTemplate)) {
    return null;
  }
  return (
    <Fragment>
      <LocalizeCheckbox
        selected={isTemplate || modalOpen || convertingToTemplate}
        onChange={() => setModalOpen(!modalOpen)}
        disabled={isTemplate || convertingToTemplate || loadingBlocs}
      />
      <LocalizeModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onLocalize={() => {
          setModalOpen(false);
          onLocalize();
        }}
      />
    </Fragment>
  );
};

export default LocalizePolicy;

const LocalizeCheckboxLabel = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {t("documentControl.create.localizePolicy.checkboxLabel")}
      <StyledFontAwesomeIcon
        icon={["fal", "question-circle"]}
        data-tip
        data-event={HOVER_EVENTS}
        data-event-off={HOVER_EVENTS_OFF}
        data-for="localize-policy"
      />
    </Fragment>
  );
};

export const LocalizeCheckbox = ({ selected, onChange, disabled }) => {
  const VALUE = "localize";
  const { t } = useTranslation();
  let selectedValues = [];
  if (selected) {
    selectedValues = [VALUE];
  }
  return (
    <Fragment>
      <CheckboxGroup
        name="localize-policy"
        onChange={onChange}
        selectedValues={selectedValues}
        px={0}
        pb={4}
      >
        <Checkbox
          value={VALUE}
          renderLabel={LocalizeCheckboxLabel}
          disabled={disabled}
        />
      </CheckboxGroup>
      <Tooltip name="localize-policy">
        {t("documentControl.create.localizePolicy.helpText")}
      </Tooltip>
    </Fragment>
  );
};

const LocalizeModalFooter = ({ onClose, onLocalize }) => {
  const { t } = useTranslation();
  return (
    <ModalFooter>
      <Flex width={1} px={5} py={3} justifyContent="space-between">
        <Box>
          <Button
            id="modal-localize-button"
            buttonStyle="secondary"
            onClick={onLocalize}
          >
            {t("documentControl.create.localizePolicy.modal.localizeButton")}
          </Button>
        </Box>
        <Box>
          <Button buttonStyle="tertiary" onClick={onClose}>
            {t("buttons.cancel")}
          </Button>
        </Box>
      </Flex>
    </ModalFooter>
  );
};

export const LocalizeModal = ({ isOpen, onClose, onLocalize }) => {
  const { t } = useTranslation();
  return (
    <Modal
      id="localize-modal"
      isOpen={isOpen}
      contentLabel={t("documentControl.create.localizePolicy.modal.header")}
      onRequestClose={onClose}
      renderFooter={() => (
        <LocalizeModalFooter onClose={onClose} onLocalize={onLocalize} />
      )}
      minWidth={[320, 320, 400]}
    >
      <Text px={5} py={4} width={[320, 320, 400]}>
        {t("documentControl.create.localizePolicy.modal.body")}
      </Text>
    </Modal>
  );
};

/**
 * @flow
 * @relayHash 8ee014260c7c807f893c84906f69ddb9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnpublishedAdoptedSystemDocumentTemplatesQueryVariables = {|
  after?: ?string,
  first?: ?number,
  sort?: ?string,
|};
export type UnpublishedAdoptedSystemDocumentTemplatesQueryResponse = {|
  +unpublishedAdoptedSystemDocumentTemplates: ?{|
    +adoptedSystemDocumentTemplates: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: ?string,
          +total: ?number,
          +adopted: ?number,
          +documentPk: ?number,
        |}
      |}>,
    |},
    +error: ?{|
      +statusCode: ?number
    |},
    +otherTabTotal: ?number,
  |}
|};
export type UnpublishedAdoptedSystemDocumentTemplatesQuery = {|
  variables: UnpublishedAdoptedSystemDocumentTemplatesQueryVariables,
  response: UnpublishedAdoptedSystemDocumentTemplatesQueryResponse,
|};
*/


/*
query UnpublishedAdoptedSystemDocumentTemplatesQuery(
  $after: String
  $first: Int
  $sort: String
) {
  unpublishedAdoptedSystemDocumentTemplates(sort: $sort) {
    adoptedSystemDocumentTemplates(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          name
          total
          adopted
          documentPk
        }
      }
    }
    error {
      statusCode
    }
    otherTabTotal
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "unpublishedAdoptedSystemDocumentTemplates",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort",
        "type": "String"
      }
    ],
    "concreteType": "AdoptedSystemDocumentTemplateListType",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adoptedSystemDocumentTemplates",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after",
            "type": "String"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first",
            "type": "Int"
          }
        ],
        "concreteType": "AdoptedSystemDocumentTemplatesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "AdoptedSystemDocumentTemplatesEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AdoptedSystemDocumentTemplateType",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pk",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "total",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "adopted",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "documentPk",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "otherTabTotal",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UnpublishedAdoptedSystemDocumentTemplatesQuery",
  "id": "9d7071e68361fb0ca3538e60b084b2ee",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UnpublishedAdoptedSystemDocumentTemplatesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UnpublishedAdoptedSystemDocumentTemplatesQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query UnpublishedAdoptedSystemDocumentTemplatesQuery(\n  $after: String\n  $first: Int\n  $sort: String\n) {\n  unpublishedAdoptedSystemDocumentTemplates(sort: $sort) {\n    adoptedSystemDocumentTemplates(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          total\n          adopted\n          documentPk\n        }\n      }\n    }\n    error {\n      statusCode\n    }\n    otherTabTotal\n  }\n}\n";
(node/*: any*/).hash = 'ec99541481c118770945e391e4a27cac';
module.exports = node;

import { useTranslation } from "react-i18next";

import { ContentContainer } from "../../pages";

import { AdvancedReportPage } from "pstat-anywhere/components/pages/reports";
import useQueryVariables from "pstat-anywhere/components/pages/reports/hooks/useQueryVariables";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import {
  useTenant,
  withTenantContext
} from "pstat-anywhere/context_providers/TenantContext";
import { useViewer } from "pstat-anywhere/context_providers/ViewerContext";
import titleCase from "pstat-anywhere/utils/titleCase";


const addIsEditableToVersions = (versions, editablePks) => {
  if (versions?.edges) {
    const versionEdges = versions.edges;
    const updatedVersionEdges = versionEdges.map(edge => {
      const version = edge.node;
      let updatedVersion;
      if (version.pk && editablePks.indexOf(version.pk) > -1) {
        updatedVersion = { ...version, isEditable: true };
      } else {
        updatedVersion = { ...version, isEditable: false };
      }
      return { ...edge, node: updatedVersion };
    });
    versions.edges = updatedVersionEdges;
  }
};

const addDocumentPkToVersions = (versions, documentPk) => {
  if (versions?.edges) {
    const versionEdges = versions.edges;
    const updatedVersionEdges = versionEdges.map(edge => {
      const version = edge.node;
      const updatedVersion = { ...version, documentPk };
      return { ...edge, node: updatedVersion };
    });
    versions.edges = updatedVersionEdges;
  }
};

const PolicyRevisions = ({
  documentRevisions,
  route,
  router,
  match,
  location,
  labels,
  tenant
}) => {
  const { t } = useTranslation();
  const {
    userAuth: { isSiteAdmin }
  } = useViewer();
  const {
    enableEnterprise,
    customerSettings: { enableEnterprisePolicySharing }
  } = useTenant();
  const { variables } = useQueryVariables({ location });
  const versions = {
    totalCount:
      documentRevisions?.versions.totalCount +
      documentRevisions?.preimportVersions.totalCount,
    edges: documentRevisions?.versions.edges.concat(
      documentRevisions?.preimportVersions.edges
    )
  };
  const edges = versions.edges;
  const hasPermissionsToViewSharedPolicies =
    enableEnterprise && enableEnterprisePolicySharing && isSiteAdmin;
  let showTemplatePoliciesLink = false;
  if (hasPermissionsToViewSharedPolicies && edges) {
    showTemplatePoliciesLink = !!edges[0].node.systemDocumentTemplateExtension;
  }
  const editablePks = documentRevisions?.editablePks;
  addIsEditableToVersions(versions, editablePks);
  addDocumentPkToVersions(versions, match?.params?.documentPk);
  let tableType = null;
  if (showTemplatePoliciesLink) {
    if (tenant.hasScheduledEffectiveDateEnabled) {
      tableType = "documentRevisionsWithSEDAndTemplatePoliciesLink";
    } else {
      tableType = "documentRevisionsWithTemplatePoliciesLink";
    }
  } else {
    if (tenant.hasScheduledEffectiveDateEnabled) {
      tableType = "documentRevisionsWithSED";
    } else {
      tableType = "documentRevisions";
    }
  }
  return (
    <ContentContainer>
      <AdvancedReportPage
        variables={variables}
        data={versions}
        route={route}
        router={router}
        location={location}
        headerText={t("documentControl.revisions.heading", {
          documentLabel: titleCase(labels.documentLabel)
        })}
        noContentMessage={t("documentControl.revisions.noResults")}
        showActionButtonPanel={false}
        showTabsPanel={false}
        tableType={tableType}
        frozenColumnTable={true}
        noSort={true}
        rowKey={({ rowData }) => `${rowData.pk}_${rowData.approvalDate}`}
      />
    </ContentContainer>
  );
};

export default withTenantContext(withLabelContext(PolicyRevisions));

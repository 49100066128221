/**
 * @flow
 * @relayHash c98e828a56a73228fa2532072b240343
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRevisionStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "PREIMPORT" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type RevisionStatus = "NEW" | "REVISED" | "UNCHANGED" | "%future added value";
export type PolicyRevisionsQueryVariables = {|
  documentPk: number
|};
export type PolicyRevisionsQueryResponse = {|
  +documentRevisions: ?{|
    +versions: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: ?string,
          +categoryName: ?string,
          +status: ?DocumentRevisionStatus,
          +approvalDate: ?string,
          +effectiveDate: ?string,
          +revisedFromParentStatus: ?RevisionStatus,
          +systemDocumentTemplateExtension: ?{|
            +pk: ?number
          |},
        |}
      |}>,
    |},
    +preimportVersions: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: ?string,
          +categoryName: ?string,
          +status: ?DocumentRevisionStatus,
          +approvalDate: ?string,
          +effectiveDate: ?string,
          +revisedFromParentStatus: ?RevisionStatus,
        |}
      |}>,
    |},
    +editablePks: ?$ReadOnlyArray<?number>,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type PolicyRevisionsQuery = {|
  variables: PolicyRevisionsQueryVariables,
  response: PolicyRevisionsQueryResponse,
|};
*/


/*
query PolicyRevisionsQuery(
  $documentPk: Int!
) {
  documentRevisions(documentPk: $documentPk) {
    versions {
      totalCount
      edges {
        node {
          pk
          name
          categoryName
          status
          approvalDate
          effectiveDate
          revisedFromParentStatus
          systemDocumentTemplateExtension {
            pk
            id
          }
        }
      }
    }
    preimportVersions {
      totalCount
      edges {
        node {
          pk
          name
          categoryName
          status
          approvalDate
          effectiveDate
          revisedFromParentStatus
        }
      }
    }
    editablePks
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "documentPk",
    "variableName": "documentPk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "categoryName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisedFromParentStatus",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "preimportVersions",
  "storageKey": null,
  "args": null,
  "concreteType": "DocumentRevisionCountableConnection",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "DocumentRevisionCountableEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "DocumentRevisionType",
          "plural": false,
          "selections": [
            v3,
            v4,
            v5,
            v6,
            v7,
            v8,
            v9
          ]
        }
      ]
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "editablePks",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PolicyRevisionsQuery",
  "id": "a2ef649f1b00a622f1f2068926df7cce",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PolicyRevisionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentRevisions",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentRevisionsType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "versions",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentRevisionCountableConnection",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentRevisionCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentRevisionType",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      v6,
                      v7,
                      v8,
                      v9,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "systemDocumentTemplateExtension",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SystemDocumentTemplateType",
                        "plural": false,
                        "selections": [
                          v3
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v10,
          v11,
          v12
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PolicyRevisionsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentRevisions",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentRevisionsType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "versions",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentRevisionCountableConnection",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentRevisionCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentRevisionType",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v5,
                      v6,
                      v7,
                      v8,
                      v9,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "systemDocumentTemplateExtension",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SystemDocumentTemplateType",
                        "plural": false,
                        "selections": [
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v10,
          v11,
          v12
        ]
      }
    ]
  }
};
})();
node.text = "query PolicyRevisionsQuery(\n  $documentPk: Int!\n) {\n  documentRevisions(documentPk: $documentPk) {\n    versions {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          categoryName\n          status\n          approvalDate\n          effectiveDate\n          revisedFromParentStatus\n          systemDocumentTemplateExtension {\n            pk\n            id\n          }\n        }\n      }\n    }\n    preimportVersions {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          categoryName\n          status\n          approvalDate\n          effectiveDate\n          revisedFromParentStatus\n        }\n      }\n    }\n    editablePks\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = 'e0fdb475cdef024685aa42b62cfae4d7';
module.exports = node;

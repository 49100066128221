/**
 * @flow
 * @relayHash 6ee9f9a4b633d6a6a1ce55bae156fcea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type ScheduledDocumentsQueryVariables = {|
  after?: ?string,
  first?: ?number,
  sort?: ?string,
  showAll?: ?boolean,
  search_query?: ?string,
|};
export type ScheduledDocumentsQueryResponse = {|
  +scheduledDocuments: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: string,
          +effectiveDate: ?string,
          +hasAttachments: ?boolean,
          +restricted: boolean,
          +bloc: {|
            +pk: ?number,
            +name: string,
          |},
          +applicabilityType: ?ApplicabilityType,
          +category: {|
            +name: string
          |},
          +documentsOfTemplate: ?{|
            +documents: ?$ReadOnlyArray<?{|
              +pk: ?number,
              +name: string,
              +hasAttachments: ?boolean,
              +bloc: {|
                +name: string,
                +pk: ?number,
              |},
              +applicabilityType: ?ApplicabilityType,
              +category: {|
                +name: string
              |},
              +effectiveDate: ?string,
              +restricted: boolean,
            |}>
          |},
        |}
      |}>,
    |},
    +error: ?{|
      +statusCode: ?number
    |},
  |}
|};
export type ScheduledDocumentsQuery = {|
  variables: ScheduledDocumentsQueryVariables,
  response: ScheduledDocumentsQueryResponse,
|};
*/


/*
query ScheduledDocumentsQuery(
  $after: String
  $first: Int
  $sort: String
  $showAll: Boolean
  $search_query: String
) {
  scheduledDocuments(sort: $sort, showAll: $showAll, q: $search_query) {
    documents(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          name
          effectiveDate
          hasAttachments
          restricted
          bloc {
            pk
            name
            id
          }
          applicabilityType
          category {
            name
            id
          }
          documentsOfTemplate {
            documents {
              pk
              name
              hasAttachments
              bloc {
                name
                pk
                id
              }
              applicabilityType
              category {
                name
                id
              }
              effectiveDate
              restricted
              id
            }
          }
          id
        }
      }
    }
    error {
      statusCode
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "showAll",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search_query",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "search_query",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "showAll",
    "variableName": "showAll",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasAttachments",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": [
    v5
  ]
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    }
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": [
    v5,
    v12
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ScheduledDocumentsQuery",
  "id": "a94a41bbbf7f5a2a99bbc27fd5b51971",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ScheduledDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "scheduledDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "ScheduledDocumentsReportType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      v8,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5
                        ]
                      },
                      v9,
                      v10,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v4,
                              v5,
                              v7,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v5,
                                  v4
                                ]
                              },
                              v9,
                              v10,
                              v6,
                              v8
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v11
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ScheduledDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "scheduledDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "ScheduledDocumentsReportType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      v8,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v12
                        ]
                      },
                      v9,
                      v13,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v4,
                              v5,
                              v7,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v5,
                                  v4,
                                  v12
                                ]
                              },
                              v9,
                              v13,
                              v6,
                              v8,
                              v12
                            ]
                          }
                        ]
                      },
                      v12
                    ]
                  }
                ]
              }
            ]
          },
          v11
        ]
      }
    ]
  }
};
})();
node.text = "query ScheduledDocumentsQuery(\n  $after: String\n  $first: Int\n  $sort: String\n  $showAll: Boolean\n  $search_query: String\n) {\n  scheduledDocuments(sort: $sort, showAll: $showAll, q: $search_query) {\n    documents(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          effectiveDate\n          hasAttachments\n          restricted\n          bloc {\n            pk\n            name\n            id\n          }\n          applicabilityType\n          category {\n            name\n            id\n          }\n          documentsOfTemplate {\n            documents {\n              pk\n              name\n              hasAttachments\n              bloc {\n                name\n                pk\n                id\n              }\n              applicabilityType\n              category {\n                name\n                id\n              }\n              effectiveDate\n              restricted\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n    error {\n      statusCode\n    }\n  }\n}\n";
(node/*: any*/).hash = '619ba8fe8cb2184a855871d5c7da2558';
module.exports = node;

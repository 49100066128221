import RedirectException from "found/lib/RedirectException";
import queryString from "query-string";

import {
  PageNotFound404Error,
  PermissionDenied403Error
} from "pstat-anywhere/errors";

export const FROM_URL_PARAM = "from";

const getRootUrl = () => window.ROOT_URL_PATH || "";

const isDevMode = () => process.env.NODE_ENV === "development";

export { getRootUrl, isDevMode };

export const checkForRedirect = (
  props,
  queryName,
  includeCurrentQueryString = false
) => {
  if (props && props[queryName]) {
    let redirectUrl = props[queryName].redirectUrl;
    if (redirectUrl) {
      if (includeCurrentQueryString) {
        const queryString = props.location.search;
        redirectUrl += queryString;
      }
      throw new RedirectException(redirectUrl);
    }
  }
};

export const checkForError = (props, queryName) => {
  if (props && props[queryName]) {
    const queryProps = props[queryName];
    const error = queryProps.error;
    if (error) {
      if (error.statusCode === 404) {
        throw new PageNotFound404Error();
      } else if (error.statusCode === 403) {
        throw new PermissionDenied403Error(error.message, {
          tenantsToRedirect: queryProps?.tenantsToRedirect,
          documentPk: props?.params?.documentPk,
          routeName: props?.route?.data?.routeName
        });
      } else {
        throw new Error();
      }
    }
  }
};

export const getUrl = urlPath => {
  if (isDevMode()) {
    return `http://dmi-scranton.policystat.localhost:8000${urlPath}`;
  }
  return urlPath;
};

export const makeRedirect = (destination, params) => {
  const redirectKey = "next=";
  return (
    redirectKey + encodeURIComponent(destination) + encodeURIComponent(params)
  );
};

export const addRedirect = (destination, params) => {
  return "?" + makeRedirect(destination, params);
};

export const addParams = (url, params) => {
  if (!url || !params) {
    return url;
  }
  const urlParams = queryString.stringify(params);
  if (!urlParams.toString()) {
    return url;
  }
  return `${url}?${urlParams}`;
};

import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { ContentContainer } from "pstat-anywhere/components/pages";
import AdvancedReportPage from "pstat-anywhere/components/pages/reports/AdvancedReportPage";
import LocalDocumentsForTemplateCsvQuery from "pstat-anywhere/components/system_document_templates/LocalDocumentsForTemplateCsvQuery";
import UnpublishedDocumentsForTemplateCsvQuery from "pstat-anywhere/components/system_document_templates/UnpublishedDocumentsForTemplateCsvQuery";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import { isOld, isOldDraft } from "pstat-anywhere/utils/document";
import titleCase from "pstat-anywhere/utils/titleCase";
import {
  FROM_URL_PARAM,
  addParams,
  getRootUrl
} from "pstat-anywhere/utils/urls";
import Breadcrumb from "pstat-design-system/Breadcrumb";

const PublishedTemplatesBreadcrumb = ({ backToAllVersionsPath }) => {
  const { t } = useTranslation();

  let text = t("systemDocumentTemplates.localDocumentsForTemplate.breadcrumb");
  let path = `${getRootUrl()}/template/published`;
  if (backToAllVersionsPath) {
    text = t(
      "systemDocumentTemplates.localDocumentsForTemplate.allVersionsBreadcrumb"
    );
    path = backToAllVersionsPath;
  }
  return <Breadcrumb text={text} to={path} />;
};

const LocalDocumentsForTemplatePage = ({
  blocSystemDocumentTemplatesDocuments,
  variables,
  location,
  route,
  router,
  routeParams
}) => {
  const {
    blocSystemDocumentTemplates,
    systemDocumentTemplateName,
    systemDocumentTemplateStatus,
    unpublishedDocumentsCount
  } = blocSystemDocumentTemplatesDocuments || {};
  const totalCount = blocSystemDocumentTemplates?.totalCount;
  const edges = getUpdatedEdges(blocSystemDocumentTemplates?.edges);
  const data = {
    edges,
    totalCount
  };
  return (
    <LocalDocumentsForTemplate
      data={data}
      showSubscribed={true}
      templatePk={routeParams?.pk}
      systemDocumentTemplateName={systemDocumentTemplateName}
      systemDocumentTemplateStatus={systemDocumentTemplateStatus}
      publishedCount={totalCount}
      unpublishedCount={unpublishedDocumentsCount}
      variables={variables}
      route={route}
      router={router}
      location={location}
      csvQuery={LocalDocumentsForTemplateCsvQuery}
      csvQueryName="blocSystemDocumentTemplatesDocumentsCsv"
    />
  );
};

export default LocalDocumentsForTemplatePage;

export const UnpublishedDocumentsForTemplatePage = ({
  hiddenSystemDocumentTemplatesDocuments,
  variables,
  location,
  route,
  router,
  routeParams
}) => {
  const {
    documents,
    systemDocumentTemplateName,
    systemDocumentTemplateStatus,
    publishedDocumentsCount
  } = hiddenSystemDocumentTemplatesDocuments || {};
  const totalCount = documents?.totalCount;
  const edges = getUpdatedEdges(documents?.edges);
  const data = {
    edges,
    totalCount
  };
  return (
    <LocalDocumentsForTemplate
      data={data}
      showSubscribed={false}
      templatePk={routeParams?.pk}
      systemDocumentTemplateName={systemDocumentTemplateName}
      systemDocumentTemplateStatus={systemDocumentTemplateStatus}
      publishedCount={publishedDocumentsCount}
      unpublishedCount={totalCount}
      variables={variables}
      route={route}
      router={router}
      location={location}
      csvQuery={UnpublishedDocumentsForTemplateCsvQuery}
      csvQueryName="hiddenSystemDocumentTemplatesDocumentsCsv"
    />
  );
};

const LocalDocumentsForTemplate = ({
  data,
  templatePk,
  systemDocumentTemplateName,
  systemDocumentTemplateStatus,
  publishedCount,
  unpublishedCount,
  showSubscribed,
  variables,
  route,
  router,
  location,
  csvQuery,
  csvQueryName
}) => {
  const { t } = useTranslation();
  const labels = useLabels();
  const parsed = queryString.parse(window.location.search);
  const backToAllVersionsPath = parsed[FROM_URL_PARAM];
  const documentLabelPlural = titleCase(labels.documentLabelPlural);
  const subscribedPath = `template/${templatePk}/subscribed`;
  const unsubscribedPath = `template/${templatePk}/unsubscribed`;
  const tabs = [
    {
      id: "documents_for_template_subscribed",
      textKey:
        "systemDocumentTemplates.localDocumentsForTemplate.tabs.subscribedTab",
      path: backToAllVersionsPath
        ? addParams(subscribedPath, { [FROM_URL_PARAM]: backToAllVersionsPath })
        : subscribedPath,
      count: publishedCount
    },
    {
      id: "documents_for_template_unsubscribed",
      textKey:
        "systemDocumentTemplates.localDocumentsForTemplate.tabs.unsubscribedTab",
      path: backToAllVersionsPath
        ? addParams(unsubscribedPath, {
            [FROM_URL_PARAM]: backToAllVersionsPath
          })
        : unsubscribedPath,
      count: unpublishedCount
    }
  ];
  let tableType = "localDocumentsForTemplate";
  let emptyMessageTextKey =
    "systemDocumentTemplates.localDocumentsForTemplate.emptyMessage";
  if (!showSubscribed) {
    tableType = "unpublishedDocumentsForTemplate";
    emptyMessageTextKey =
      "systemDocumentTemplates.localDocumentsForTemplate.unsubscribedEmptyMessage";
  }
  const isOlderVersion = status => isOld(status) || isOldDraft(status);
  let headerText = t(
    "systemDocumentTemplates.localDocumentsForTemplate.header",
    { documentLabelPlural, templateName: systemDocumentTemplateName }
  );
  if (isOlderVersion(systemDocumentTemplateStatus)) {
    headerText = t(
      "systemDocumentTemplates.localDocumentsForTemplate.oldHeader",
      { templateName: systemDocumentTemplateName }
    );
    tabs[0].infoText = t(
      "systemDocumentTemplates.localDocumentsForTemplate.tabs.subscribedTab.oldInfoTooltip"
    );
    tabs[1].infoText = t(
      "systemDocumentTemplates.localDocumentsForTemplate.tabs.unsubscribedTab.oldInfoTooltip"
    );
  }
  return (
    <ContentContainer>
      <AdvancedReportPage
        variables={variables}
        data={data}
        tabs={tabs}
        route={route}
        router={router}
        location={location}
        headerText={headerText}
        noContentMessage={t(emptyMessageTextKey, {
          documentLabelPlural: documentLabelPlural.toLowerCase(),
          templateName: systemDocumentTemplateName
        })}
        showPagination={true}
        showHitsPerPageSelector={true}
        showTabsPanel={true}
        tableType={tableType}
        customOnRow={() => {}}
        showActionButtonPanel={true}
        frozenColumnTable={true}
        BreadcrumbComponent={() => (
          <PublishedTemplatesBreadcrumb
            backToAllVersionsPath={backToAllVersionsPath}
          />
        )}
        csvQueryArgs={{ pk: templatePk }}
        CSVQuery={csvQuery}
        csvQueryName={csvQueryName}
      />
    </ContentContainer>
  );
};

const getUpdatedEdges = edges => {
  return edges?.map(edge => {
    const tenant = edge.node.bloc.tenants[0];
    return {
      node: {
        ...edge.node,
        tenantName: tenant.name
      }
    };
  });
};

import { max } from "date-fns";
import { Flex } from "grid-styled";
import mailToLink from "mailto-link";
import { Fragment, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";

import {
  LocalApplicabilityIcon,
  LocalizedApplicabilityIcon,
  StyledFontAwesomeIcon,
  SystemApplicabilityIcon
} from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import {
  dateParser,
  validateAndFormatDate,
  validateAndFormatDistanceToNow
} from "pstat-anywhere/utils/dateHelpers";
import {
  isActive,
  parseDocumentStatusToTranslationKey
} from "pstat-anywhere/utils/document";
import titleCase from "pstat-anywhere/utils/titleCase";
import {
  FROM_URL_PARAM,
  addParams,
  getRootUrl
} from "pstat-anywhere/utils/urls";
import { userDisplay } from "pstat-anywhere/utils/userDisplay";
import { Badge } from "pstat-design-system/Badge";
import AutocompleteTextInput from "pstat-design-system/inputs/AutocompleteTextInput";
import { FocusLink } from "pstat-design-system/Link";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import Link from "pstat-design-system/RouterFocusLink";
import { Button } from "pstat-design-system/shared/Button";
import MenuButton from "pstat-design-system/shared/MenuButton";
import { Text } from "pstat-design-system/text";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

const NullDateMessage = () => {
  const { t } = useTranslation();

  return t("dates.nullDate");
};

const AnyMessage = () => {
  const { t } = useTranslation();
  return t("tables.labels.any");
};

export const formatDate = date => {
  // effective date value can be "Upon Approval", which is not a date.
  // In this case, original date string should be returned

  return validateAndFormatDate({
    date,
    formatString: "monthName-day-year",
    nullableValue: <NullDateMessage />,
    fallbackValue: date
  });
};

export const formatValueOrEmpty = value => value || "—";

export const formatYesNo = value => (value ? "Yes" : "No");

export const formatDateShort = date => {
  return validateAndFormatDate({
    date,
    formatString: "shortMonth-day-year",
    nullableValue: "—",
    fallbackValue: "—"
  });
};

export const formatDateShortNoDay = date => {
  return validateAndFormatDate({
    date: date,
    formatString: "month-year",
    nullableValue: "—",
    fallbackValue: "—"
  });
};

export const formatApprovers = (approvers, { rowData }) => {
  if (approvers && approvers.length > 0) {
    return approvers.map((approver, index) => (
      <ApproversListContent approver={approver} rowData={rowData} key={index} />
    ));
  }
  return <AnyMessage />;
};

export const formatDateTimeDistance = date => {
  return validateAndFormatDistanceToNow(date);
};

const ApproversListContent = withLabelContext(
  ({ labels, approver, rowData }) => {
    const { t } = useTranslation();
    const emailBody = t("documentReports.awaitingActivation.emailBody", {
      documentLabel: labels.documentLabel,
      documentName: rowData.name,
      waitTime: formatDateTimeDistance(
        rowData.approvalWorkflow.currentStep.created
      ),
      approvalLink: `${window.location.origin}/${getRootUrl()}/policy/${
        rowData.pk
      }`
    });
    return <UserMailToLink user={approver} emailBody={emailBody} />;
  }
);

export const formatTemplateAdoptionAuthor = (author, { rowData }) => {
  if (author) {
    return (
      <TemplateAdoptionAuthor
        author={author}
        document={rowData.document}
        publishDate={rowData.publishDate}
      />
    );
  }
  return null;
};

const TemplateAdoptionAuthor = withLabelContext(
  ({ labels, author, document, publishDate }) => {
    const { t } = useTranslation();
    let emailBodyKey =
      "systemDocumentTemplates.localDocumentsForTemplate.authorEmail";
    if (isActive(document.status)) {
      emailBodyKey =
        "systemDocumentTemplates.localDocumentsForTemplate.authorEmailActive";
    }
    const emailBody = t(emailBodyKey, {
      documentLabel: labels.documentLabel.toLowerCase(),
      publishDate: formatDate(publishDate),
      documentLink: document.link
    });
    return <UserMailToLink user={author} emailBody={emailBody} />;
  }
);

const UserMailToLink = ({ user, emailBody }) => {
  const href = mailToLink({
    to: user.notificationEmail ? user.notificationEmail : user.email,
    body: emailBody
  });
  return (
    <Link id={`mail-approver-${user.pk}`} target="_blank" to={href}>
      <StyledFontAwesomeIcon icon={["fal", "envelope"]} color="secondary.0" />
      {userDisplay(user)}
    </Link>
  );
};

const WaitTimeContent = props => (
  <Fragment>
    <StyledFontAwesomeIcon
      data-tip
      data-for={props.tooltipId}
      data-event="mouseover keydown"
      data-event-off="mouseout keyup"
      icon={["fal", "info-circle"]}
      color="secondary.15"
      tabIndex="0"
    />{" "}
    {props.children}
    <Tooltip name={props.tooltipId}>{props.tooltipText}</Tooltip>
  </Fragment>
);

const WaitTimeForLine = withLabelContext(props => {
  const { t } = useTranslation();
  const allAcksAcknowledged =
    props.rowData.totalAcknowledged === props.rowData.totalAssigned;
  const tooltipId = `wait-time-info-${props.rowIndex}`;
  const documentLabel = props.labels.documentLabel.toLowerCase();

  let tooltipText = t("tables.formatters.waitTimeTooltip.default");
  if (allAcksAcknowledged) {
    tooltipText = t("tables.formatters.waitTimeTooltip.allAcksAcknowledged", {
      documentLabel
    });
  }
  return (
    <WaitTimeContent tooltipText={tooltipText} tooltipId={tooltipId}>
      {props.waitTime}
    </WaitTimeContent>
  );
});

export const formatWaitTimeForLine = (waitTime, { rowIndex, rowData }) => (
  <WaitTimeForLine waitTime={waitTime} rowIndex={rowIndex} rowData={rowData} />
);

const WaitTimeForDocument = withLabelContext(props => {
  const { t } = useTranslation();
  const allAcksAcknowledged = props.rowData.dateAcknowledged;
  const tooltipId = `wait-time-info-${props.rowIndex}`;
  const documentLabel = props.labels.documentLabel.toLowerCase();

  let tooltipText = t("tables.formatters.waitTimeTooltip.default");
  if (allAcksAcknowledged) {
    tooltipText = t(
      "tables.formatters.waitTimeForDocumentTooltip.allAcknsAcknowledged",
      { documentLabel }
    );
  }
  return (
    <WaitTimeContent tooltipText={tooltipText} tooltipId={tooltipId}>
      {props.waitTime}
    </WaitTimeContent>
  );
});

export const formatWaitTimeForDocument = (waitTime, { rowIndex, rowData }) => {
  if (waitTime) {
    return (
      <WaitTimeForDocument
        waitTime={waitTime}
        rowIndex={rowIndex}
        rowData={rowData}
      />
    );
  }
  return "—";
};

const AcknowledgedDateContent = props => {
  const date = props.date;
  const iconProps = {};
  let shouldUseTranslation = false;
  let text = date;
  if (date) {
    iconProps.icon = ["fal", "check-circle"];
    iconProps.color = "messages.success";

    text = validateAndFormatDate({
      date,
      formatString: "shortMonth-day-year"
    });
  } else {
    shouldUseTranslation = true;
    iconProps.icon = ["fal", "exclamation-triangle"];
    iconProps.color = "messages.error";
    text = "acknowledgments.mine.tabs.outstanding";
  }
  return (
    <Fragment>
      <StyledFontAwesomeIcon {...iconProps} />{" "}
      {shouldUseTranslation ? <Trans i18nKey={text} /> : text}
    </Fragment>
  );
};

export const formatAcknowledgedDateForLine = date => {
  return <AcknowledgedDateContent date={date} />;
};

export const formatAcknowledgedDateForDocument = (date, { rowData }) => {
  if (rowData.dateAssigned) {
    return <AcknowledgedDateContent date={date} />;
  }
  return "—";
};

export const formatStatus = status => {
  if (!status) {
    return status;
  }
  const DocumentTranslatedStatus = ({ status }) => {
    const { t } = useTranslation();
    return t(parseDocumentStatusToTranslationKey(status));
  };

  return <DocumentTranslatedStatus status={status} />;
};

const Preview = styled("span")`
  strong {
    font-weight: ${themeGet("fontWeights.medium")};
  }
`;

export const formatPreview = preview => (
  <Preview dangerouslySetInnerHTML={{ __html: preview }} />
);

const HomeIcon = withLabelContext(
  ({ applicabilityType, applicability, documentId, labels }) => {
    const { documentLabel } = labels;
    const documentLabelLower = documentLabel ? documentLabel.toLowerCase() : "";
    const { t } = useTranslation();
    if (applicabilityType === "LOCAL") {
      return (
        <Fragment>
          <LocalApplicabilityIcon documentId={documentId} />
          <Tooltip name={`system-bloc-tooltip-${documentId}`}>
            {t("documentReports.homeBlocTooltip", {
              applicability,
              documentLabel: documentLabelLower
            })}
          </Tooltip>
        </Fragment>
      );
    }
    const tooltipTranslationKey =
      applicabilityType === "LOCALIZED"
        ? "localizedBlocTooltip"
        : "systemBlocTooltip";
    return (
      <Fragment>
        {applicabilityType === "LOCALIZED" ? (
          <LocalizedApplicabilityIcon documentId={documentId} />
        ) : (
          <SystemApplicabilityIcon documentId={documentId} />
        )}
        <Tooltip name={`system-bloc-tooltip-${documentId}`}>
          {t(`documentReports.${tooltipTranslationKey}`, {
            applicability,
            documentLabel: documentLabelLower
          })}
        </Tooltip>
      </Fragment>
    );
  }
);

export const formatApplicabilityWithHomeIcon = (applicability, { rowData }) => {
  const { applicabilityType, pk, isTemplate } = rowData;
  const homeIconType = isTemplate ? "LOCAL" : applicabilityType;
  if (homeIconType) {
    return (
      <Fragment>
        {applicability}{" "}
        <HomeIcon
          applicabilityType={homeIconType}
          applicability={applicability}
          documentId={pk}
        />
      </Fragment>
    );
  }
  return applicability;
};

const BlocNamesSelector = ({ items, selectedBloc, onSuggestionSelected }) => {
  return (
    <AutocompleteTextInput
      data-testid="bloc-name-selector"
      maximumWidth={235}
      selectedSuggestions={[selectedBloc]}
      onSuggestionSelected={onSuggestionSelected}
      suggestions={items}
      getSuggestionPk={({ bloc }) => bloc.pk}
      getSuggestionValue={({ bloc }) => bloc.name}
      isBadgedInput={true}
    />
  );
};

export const formatApplicabilityWithHomeIconOrListApplicability = (
  applicability,
  { rowData }
) => {
  const {
    documentsOfTemplate,
    originPk,
    bloc: { pk: blocPk },
    pk: documentPk,
    onChangeAdoptedDocument
  } = rowData;
  const adoptedDocs = documentsOfTemplate?.documents || [];
  if (adoptedDocs.length === 0) {
    return formatApplicabilityWithHomeIcon(applicability, { rowData });
  }

  return (
    <Fragment>
      <BlocNamesSelector
        selectedBloc={rowData}
        items={[rowData, ...adoptedDocs]}
        title={applicability}
        blocPk={blocPk}
        onSuggestionSelected={suggestion =>
          onChangeAdoptedDocument(suggestion, originPk || documentPk)
        }
      />
    </Fragment>
  );
};

export const formatDocumentNameWithAttachmentIcon = (title, { rowData }) => {
  if (rowData.hasAttachments) {
    return (
      <Fragment>
        {title}{" "}
        <StyledFontAwesomeIcon
          icon={["fal", "paperclip"]}
          size="lg"
          color="secondary.0"
        />
      </Fragment>
    );
  }
  return title;
};

export const formatDocumentNameWithLockIcon = (title, { rowData }) => {
  const isRestrictedDefaultPages = rowData.restricted;

  //This condition is used only on "Your Acknowledgment History" report page
  const isRestrictedAcknowledgmentReport = rowData.document?.restricted;

  //This condition is used only on "Saved Drafts" report page
  const isRestrictedDraftPage = rowData.savedCopy?.restricted;

  const isRestricted =
    isRestrictedDefaultPages ||
    isRestrictedAcknowledgmentReport ||
    isRestrictedDraftPage;

  if (isRestricted) {
    return (
      <Fragment>
        {title}{" "}
        <StyledFontAwesomeIcon
          icon={["fas", "lock"]}
          size="lg"
          color="secondary.0"
        />
      </Fragment>
    );
  }
  return title;
};

const getLastDateAndFormat = dates => {
  const newestDate = max(dates);

  return validateAndFormatDate({
    date: newestDate,
    formatString: "monthName-day-year"
  });
};

export const formatEpReportAssociatedDateField = data => {
  const dates = data.map(association =>
    dateParser(association.associationDatetime)
  );

  return getLastDateAndFormat(dates);
};

export const formatEpReportLastEpUpdateDateField = data => {
  const dates = data.map(association =>
    dateParser(association.epContent?.lastChanged)
  );

  return getLastDateAndFormat(dates);
};

export const formatEpReportNameField = loadEPContent => data => {
  return data.map(association => {
    if (!association.epContent) {
      return null;
    }
    return (
      <MenuButton
        key={association.id}
        onClick={() => loadEPContent(association)}
        whiteSpace="wrap"
        textAlign="left"
      >
        {association.epContent?.name} <br />
      </MenuButton>
    );
  });
};

const DraftType = withLabelContext(({ isEditType, labels }) => {
  const { t } = useTranslation();
  const documentLabel = titleCase(labels.documentLabel);
  if (isEditType) {
    return t("documentDraft.table.draftType.editDraft", { documentLabel });
  }
  return t("documentDraft.table.draftType.newDraft", { documentLabel });
});

export const formatDraftType = isEditType => (
  <DraftType isEditType={isEditType} />
);

export const formatDraftSaver = saverDisplay => {
  if (saverDisplay !== "") {
    return saverDisplay;
  }
  return <Trans i18nKey="documentDraft.table.creator.you" />;
};

const EditDocumentLink = ({ to, children, index }) => (
  <Fragment>
    <Link
      id={`edit-action-${index}`}
      to={to}
      data-tip
      data-for={`edit-icon-tooltip-${index}`}
      data-event={HOVER_EVENTS}
      data-event-off={HOVER_EVENTS_OFF}
    >
      {children}
      <StyledFontAwesomeIcon icon={["fas", "edit"]} size="lg" mx={2} />
    </Link>
    <Tooltip name={`edit-icon-tooltip-${index}`}>
      <Trans i18nKey="documentControl.view.secondaryNav.navOptions.edit.name" />
    </Tooltip>
  </Fragment>
);

export const editLink = (value, { rowData }) => {
  const [documentToEditPk, pagePath] = rowData.document
    ? [rowData.document.pk, "edit"]
    : [rowData.savedCopy.pk, "create"];
  return (
    <EditDocumentLink
      to={`${getRootUrl()}/policy/${documentToEditPk}/${pagePath}`}
      index={rowData._index}
    >
      {value}
    </EditDocumentLink>
  );
};

const ViewAdoptedDocumentsLink = withLabelContext(
  ({ labels, pk, isPublished, documentPk }) => {
    const { t } = useTranslation();
    const { documentLabelPlural } = labels;
    let to = `${getRootUrl()}/template/${pk}`;
    if (isPublished) {
      to = to + "/subscribed";
    } else {
      to = to + "/unsubscribed";
    }
    if (documentPk) {
      const fromPath = `${getRootUrl()}/policy/${documentPk}/revisions`;
      to = addParams(to, { [FROM_URL_PARAM]: fromPath });
    }

    return (
      <Link to={to}>
        {t("systemDocumentTemplates.published.locallyAdopted.viewLink", {
          documentLabelPlural: titleCase(documentLabelPlural)
        })}
      </Link>
    );
  }
);

const UnpublishAdoptedDocumentLink = ({
  templateId,
  templateName,
  total,
  unpublishTemplateHandler
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const onUnpublishHandler = () => {
    unpublishTemplateHandler(
      templateId,
      templateName,
      setLoading,
      setConfirmationModalIsOpen
    );
  };
  return (
    <Fragment>
      <FocusLink
        onActivation={() => setConfirmationModalIsOpen(true)}
        fontSize={1}
        fontWeight="medium"
        textDecoration="underline"
      >
        {t("systemDocumentTemplates.manage.unpublishButton")}
      </FocusLink>
      <UnpublishConfirmationModal
        isOpen={confirmationModalIsOpen}
        onClose={() => setConfirmationModalIsOpen(false)}
        onUnpublish={onUnpublishHandler}
        templateName={templateName}
        totalSites={total}
        waitingForUnpublish={loading}
      />
    </Fragment>
  );
};

export const UnpublishConfirmationModal = ({
  isOpen,
  onClose,
  templateName,
  totalSites,
  onUnpublish,
  waitingForUnpublish
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      id="unpublish_confirmation_modal"
      contentLabel={t(
        "systemDocumentTemplates.published.unpublishConfirmationModal.header",
        { templateName }
      )}
      isOpen={isOpen}
      onRequestClose={onClose}
      renderFooter={() => (
        <ModalFooter
          display="flex"
          justifyContent="space-between"
          px={5}
          py={3}
        >
          <Button buttonStyle="tertiary" onClick={onClose}>
            {t("buttons.cancel")}
          </Button>
          <Button
            buttonStyle="danger"
            onClick={onUnpublish}
            processing={waitingForUnpublish}
          >
            {t(
              "systemDocumentTemplates.published.unpublishConfirmationModal.unpublishButton",
              { totalSites }
            )}
          </Button>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} pt={4}>
        <Text>
          {t(
            "systemDocumentTemplates.published.unpublishConfirmationModal.content",
            { totalSites, templateName }
          )}
        </Text>
      </Flex>
    </Modal>
  );
};

export const viewAdoptedTemplateActions = isPublished => (
  value,
  { rowData }
) => {
  const { pk, unpublishTemplateHandler, name, total } = rowData;
  return (
    <Fragment>
      <ViewAdoptedDocumentsLink pk={pk} isPublished={isPublished} />
      {isPublished && (
        <UnpublishAdoptedDocumentLink
          templateId={pk}
          templateName={name}
          total={total}
          unpublishTemplateHandler={unpublishTemplateHandler}
        />
      )}
    </Fragment>
  );
};

export const allVersionsAdoptedDocumentsLink = (value, { rowData }) => {
  const { status, systemDocumentTemplateExtension } = rowData;
  const UNPUBLISHED_STATUSES = ["SAVED", "OLD_SAVE", "PENDING", "SCHEDULED"];
  if (!systemDocumentTemplateExtension) {
    return null;
  }
  if (UNPUBLISHED_STATUSES.includes(status)) {
    return null;
  }
  return (
    <ViewAdoptedDocumentsLink
      pk={systemDocumentTemplateExtension.pk}
      isPublished={true}
      documentPk={rowData.documentPk}
    />
  );
};

const AdoptedCounter = ({ adopted, total }) => {
  const { t } = useTranslation();
  return (
    <Text color="nav.25">
      {t("systemDocumentTemplates.published.locallyAdopted.counterTemplate", {
        adopted,
        total
      })}
    </Text>
  );
};

export const adoptedDocumentsCounters = (value, { rowData }) => (
  <AdoptedCounter {...rowData} />
);

const Container = styled.span`
  display: inline-flex;
  alignitems: center;
  cursor: pointer;
`;

const IconButton = ({
  children,
  onClick,
  index,
  icon,
  name,
  tooltipTextKey
}) => {
  const handleKeyDown = event => {
    const isEnterKey = event => event.key === "Enter";
    if (isEnterKey(event)) {
      onClick(event);
    }
  };
  return (
    <Fragment>
      {children}
      <Container
        id={`${name}-action-${index}`}
        data-tip
        data-for={`${name}-tooltip-${index}`}
        data-event={HOVER_EVENTS}
        data-event-off={HOVER_EVENTS_OFF}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        tabIndex="0"
      >
        <StyledFontAwesomeIcon icon={icon} size="lg" mx={2} />
        <Tooltip name={`${name}-tooltip-${index}`}>
          <Trans i18nKey={tooltipTextKey} />
        </Tooltip>
      </Container>
    </Fragment>
  );
};

const DeleteDraftButton = ({ children, onClick, index }) => (
  <IconButton
    onClick={onClick}
    index={index}
    icon={["fas", "trash-alt"]}
    name="delete-icon"
    tooltipTextKey="documentDraft.table.deleteTooltipText"
  >
    {children}
  </IconButton>
);

export const deleteButton = (value, { rowData }) => (
  <DeleteDraftButton
    onClick={() => rowData.openDiscardModal(rowData.pk)}
    index={rowData._index}
  >
    {value}
  </DeleteDraftButton>
);

export const formatAuthor = (value, { rowData }) => userDisplay(value);

export const formatImportFile = (file, { rowIndex }) => (
  <Fragment>
    <FocusLink href={file.url}>{file.name}</FocusLink>
    {file.zipName && (
      <Fragment>
        <FocusLink
          href={file.zipUrl}
          data-tip
          data-for={`zip-file-tooltip-${rowIndex}`}
          data-event={HOVER_EVENTS}
          data-event-off={HOVER_EVENTS_OFF}
        >
          <StyledFontAwesomeIcon icon={["far", "file-archive"]} mx={0} />
        </FocusLink>
        <Tooltip name={`zip-file-tooltip-${rowIndex}`}>{file.zipName}</Tooltip>
      </Fragment>
    )}
  </Fragment>
);

export const formatBlocSites = (tenants, { rowIndex }) => {
  const firstXTenants = 3;
  const firstThreeTenants = tenants.slice(0, firstXTenants);
  const moreThanThree = tenants.length > firstXTenants;
  return (
    <Fragment>
      {firstThreeTenants.map(tenant => {
        return (
          <Badge color="nav.25" fontWeight="normal" ml={0} mr={1}>
            {tenant.subdomain}
          </Badge>
        );
      })}
      {moreThanThree && (
        <Text fontSize={1} color="nav.25">
          + {tenants.length - firstXTenants} more
        </Text>
      )}
    </Fragment>
  );
};

const BlocEditIcon = ({ icon, isEditable, rowIndex }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <StyledFontAwesomeIcon
        id={`edit-icon-${rowIndex}`}
        data-tip
        data-for={`edit-icon-tooltip-${rowIndex}`}
        data-event={HOVER_EVENTS}
        data-event-off={HOVER_EVENTS_OFF}
        icon={icon}
        size="xl"
        color={isEditable ? "secondary.0" : "nav.55"}
        mx={isEditable ? 1 : 2}
      />
      <Tooltip name={`edit-icon-tooltip-${rowIndex}`}>
        {isEditable ? (
          <Fragment>
            {t("documentControl.view.secondaryNav.navOptions.edit.name")}
          </Fragment>
        ) : (
          <Fragment>{t("bloc.manage.cannotEditTooltip")}</Fragment>
        )}
      </Tooltip>
    </Fragment>
  );
};

const BlocIconLink = ({ isEditable, rowIndex, pk }) => {
  const { t } = useTranslation();
  return (
    <Link
      to={`${getRootUrl()}/bloc/${pk}/edit`}
      aria-label={t("bloc.manage.linkAriaLabel")}
    >
      <BlocEditIcon
        icon={["fas", "edit"]}
        isEditable={isEditable}
        rowIndex={rowIndex}
      />
    </Link>
  );
};

export const formatBlocEditIcon = (isEditable, { rowIndex, rowData }) => {
  if (isEditable) {
    return (
      <BlocIconLink
        isEditable={isEditable}
        rowIndex={rowIndex}
        pk={rowData.pk}
      />
    );
  } else {
    return (
      <BlocEditIcon
        icon={["fas", "edit"]}
        isEditable={isEditable}
        rowIndex={rowIndex}
      />
    );
  }
};

export const formatBlocTrashIcon = blocIsEditable => (
  isEditable,
  { rowIndex }
) => {
  return (
    <BlocEditIcon
      icon={["fas", "trash-alt"]}
      isEditable={blocIsEditable}
      rowIndex={rowIndex}
    />
  );
};

const ViewDuplicatesLabel = () => {
  const { t } = useTranslation();
  return t("documentReports.duplicates.viewDuplicates");
};
export const formatViewDuplicates = () => <ViewDuplicatesLabel />;

export const formatTags = tags => {
  const tagsText = tags.reduce((tagsText, tag, index) => {
    tagsText += tag.name;
    if (index !== tags.length - 1) {
      tagsText += ", ";
    }
    return tagsText;
  }, "");
  return tagsText;
};

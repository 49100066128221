import graphql from "babel-plugin-relay/macro";

const UnpublishedDocumentsForTemplatePageQuery = graphql`
  query UnpublishedDocumentsForTemplateQuery(
    $pk: Int!
    $after: String
    $first: Int
    $sort: String
  ) {
    hiddenSystemDocumentTemplatesDocuments(pk: $pk, sort: $sort) {
      documents(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            name
            category {
              name
            }
            bloc {
              tenants {
                name
              }
              name
            }
            author {
              firstName
              lastName
              title
            }
            effectiveDate
            status
            link(fullUrl: true)
          }
        }
      }
      systemDocumentTemplateName
      systemDocumentTemplateStatus
      error {
        statusCode
      }
      publishedDocumentsCount
    }
  }
`;

export default UnpublishedDocumentsForTemplatePageQuery;
